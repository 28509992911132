@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Arvo&family=Poppins&display=swap");

.main {
  background-image: radial-gradient(white, #c4f1f2, #7b94f4);
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: auto;
  overflow: hidden;
}
.leftSidebar {
  width: 100%;
  padding: 20px 0px;
}
.container {
  width: 35%;
  max-width: 90%;
  background-color: #ffffff;
  height: auto;
  margin: auto;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
}
.navbar img {
  height: 80px;
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightSidebarContext {
  margin: auto;
  width: 90%;
}

.btnDiv {
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.btn {
  padding: 8px 20px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #08213e;
}
.btnDiv a {
  margin-left: 40px;
  color: #000;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.moveBar {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  margin-bottom: 20px;
}
.oneHalf {
  background-color: blue;
  width: 170px;
  background: #08213e;
  border-radius: 10px;
  margin-right: 2px;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    #08213e 0%,
    #08213e 26.04%,
    #cee8ff 26.05%
  );
}
.secondHalf {
  height: 3px;
  border-radius: 20px;
}
.horn:hover {
  cursor: pointer;
  box-shadow: 0 0 2px 2px rgb(247, 234, 234);
}
.hand:hover {
  cursor: pointer;
  box-shadow: 0 0 2px 2px rgb(247, 234, 234);
}
.label1 {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.formInput input {
  border: none;
  outline: none;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  margin-left: 12px;
  background: #f9f9f9;
  width: 80%;
}
.formInput {
  border: 1px solid #cee8ff;
  padding: 8px;
  border-radius: 10px;
  background: #f9f9f9;
  width: auto;
}
.form label {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 550;
}
.form div {
  margin-top: 2px;
}

.phoneSection input {
  margin-left: 15px;
  border: 1px solid #cee8ff;
  padding: 10px;
  width: 270px;
  outline: none;
  background: #f9f9f9;
  border-radius: 10px;
  font-size: 12px;
  font-family: Poppins;
}
.selectPhone {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #cee8ff;
  border-radius: 10px;
  background: #f9f9f9;
  padding: 8px 1px;
}
.passwordInp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  border-radius: 10px;
  font-size: 12px;
  font-family: Poppins;
  border: 1px solid #cee8ff;
}
.password input {
  /* width: 270px; */
  border: none;
  outline: none;
  background: #f9f9f9;
  border-radius: 10px;
  font-size: 12px;
  font-family: Poppins;
  padding: 10px;
}
.password {
  margin-left: 10px;
}
.eye {
  margin-right: 15px;
}
.form img {
  width: 16px;
}

.descriptiontext {
  align-items: center;
  width: 200px;
}
.password_validation {
  position: absolute;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  padding: 5px;
  border-radius: 10px;
}

.alreadyaccount {
  font-size: 12px;
  cursor: pointer;
}

.alreadyaccount:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 850px) {
  .formInput,
  .passwordInp,
  .phoneSection {
    width: 100%;
  }
  .search {
    width: 100%;
  }
  .rightSidebarContext {
    margin: 0 5% 0 5%;
  }
  .phoneSection input {
    width: 80%;
  }
  .selectPhone {
    width: 20%;
  }

  .container {
    width: 90%;
    background-color: #fff;
    height: auto;
    margin: auto;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
  }

  .btnDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .btnDiv a {
    margin: auto;
  }
}
@media only screen and (max-width: 600px) {
  .rightSidebar {
    display: none;
  }

  .container {
    margin: 40px auto;
  }
}
