.outerContainer {
  background-color: #f2f7ff;
  width: 100%;
  overflow: hidden;
  height: 87vh;
}
/* Passage Question Style */
.PassageOption {
  width: 100%;
  height: 68vh;
  overflow-y: auto;
}
.PassageOption > p {
  font-size: 17px;
  margin: 0px auto 5px 20px;
  font-weight: 600;
}
.passagebtn {
  height: 30px;
  margin: 10px auto;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.allOption {
  display: flex;
  flex-direction: column;
}
.allOption > label {
  margin-left: 20px;
  font-size: 18px;
}
/* MCQ Question Styles */

.mainContainer {
  width: 100%;
  overflow: hidden;
}
.container {
  width: 100%;
  margin: auto;
  height: auto;
  background-color: #ffffff;
  overflow: hidden;
}
.questionNo {
  width: 100%;
  padding: 4px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0px 20px;
  margin: auto;
  background-color: #fff;
}
.questionNo h3 {
  color: #000;
  width: auto;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questionNo h3 span {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mainQuestion {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: auto;
  max-height: 70vh;
  /* overflow-y: auto; */
}
.question {
  width: 50%;
  min-height: 60vh;
  height: 68vh;
  /* max-height: 60vh; */
  overflow-y: auto;
  margin-top: 10px;
  border-right: 2px solid black;
}
.question p {
  color: #5b5858;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow-wrap: break-word;
}
.answer {
  width: 50%;
  min-height: 65vh;
  height: auto;
  margin-top: 10px;
}
.option {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: baseline;
  align-items: baseline;
  margin-left: 50px;
}
.option p {
  font-size: 22px;
}
.option div {
  display: flex;
  justify-content: space-around;
}
.radio_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 20px;
  cursor: pointer;
  font-size: 20px;
  color: #8b8c8c;
}
.PassageRadio_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 20px;
  cursor: pointer;
  font-size: 18px;
  color: #8b8c8c;
}

.option input[type="radio"] {
  opacity: 0;
}
.PassageOption input[type="radio"] {
  opacity: 0;
}
.design {
  width: 10px;
  height: 10px;
  border-color: black;
  border-radius: 50%;
  outline-offset: 5px;
  outline: 4px solid rgba(0, 0, 0, 0.25);
  margin-right: 40px;
}
.option input[type="radio"]:checked ~ .design {
  height: 23px;
  width: 23px;
  margin-right: 40px;
  outline-offset: 3px;
  outline: 1.3px solid #0061a8;
  background: #0061a8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 90px;
  border: 1px solid #fff;
}
.PassageOption input[type="radio"]:checked ~ .design {
  height: 23px;
  width: 23px;
  margin-right: 40px;
  outline-offset: 3px;
  outline: 1.3px solid #0061a8;
  background: #0061a8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 90px;
  border: 1px solid #fff;
}
.option input[type="radio"]:checked {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  margin-left: -6px;
}
.PassageOption input[type="radio"]:checked {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  margin-left: -6px;
}
.radio_main > input[type="radio"]:checked ~ .value {
  color: #0061a8;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: -7px;
}
.PassageRadio_main > input[type="radio"]:checked ~ .value {
  color: #0061a8;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: -7px;
}
.value {
  width: 35vw;
  margin-right: 10px;
}
.bottomBtn {
  width: 89%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}
.hideBottombtn {
  display: none;
}

.bottomBtnInCoding button {
  height: 40px;
  padding: 0px 20px;
  margin: auto 50px;
  border: none;
  border-radius: 5px;
  background: #927f7f;
  color: #9c9c9c;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-style: normal;
  margin-top: -60px;
  font-weight: 500;
}

/* Theoritical Question Styles */
.theoritical {
  background-color: #ffffff;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  height: 70vh;
}

.leftside {
  width: 50%;
  border-right: 1px solid black;
  overflow: auto;
  padding: 20px;
}
.theoritical p {
  color: #5b5858;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px auto 20px auto;
}
.mainCameraBox {
  width: 50%;
  height: 50vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cameraBox {
  /* height: 45vh; */
  border-radius: 20px;
  background: #d9d9d9;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: contain;
}
.warning {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-wrap: wrap;
}
.warning h4 {
  color: #fe0000;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: auto auto;
}

/* Coding Window Start */
.CodingMainBox {
  width: 100%;
  display: flex;
  padding: 0 0 0 0px;
}

.codingOuterContainer {
  display: flex;
  width: 40%;
  /* justify-content: center; */
  flex-direction: column;
  position: relative;
}
.questionWin {
  width: 100%;
  background-color: #fff;
  padding: 0px 10px;
  height: 65vh;
}
.questionWin p {
  color: #5b5858;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  margin: auto;
}
.codingquestion {
  width: 99%;
  padding-left: 15px;
  height: 60vh;
  overflow: auto;
}

.example {
  margin-top: 10px;
  color: #5b5858;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  /* overflow: auto; */
}

.btncontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 10vh;
  background-color: white;
}

.btncontainer button {
  height: 40px;
  padding: 0px 20px;
  border: none;
  border-radius: 5px;
  background-color: rgb(188, 165, 38);
  color: black;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
}
.codingWin {
  width: 60%;
  height: 75vh;
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
}
.selectBox {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  border-bottom: 1px solid;
}

/* Console Styles */

.consoleBox {
  width: 47%;
  min-height: 400px;
  border-radius: 20px 20px 0px 0px;
  background: white;
  position: static;
  margin: 10px 0 0 40px;
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
  z-index: 10;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 5px;
}
.consoleBox div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.consoleBoxUp div {
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.consoleBoxUp {
  width: 47%;
  min-height: 400px;
  transform: translateY(-32vh);
  margin-left: 40px;
  border-radius: 20px 20px 0px 0px;
  background: white;
  transition: all 0.5s ease-in-out;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  overflow: hidden;
}
.consoleBoxUp button {
  padding: 5px 15px;
  border-radius: 10px;
  border: none;
  border-radius: 5px;
  float: right;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  margin-right: 5%;
  margin-top: 18px;
  font-weight: 500;
  background: #008405;
}

.output {
  border-top: 1px solid white;
  color: white;
  padding: 20px 50px;
  overflow: auto;
  height: 45vh;
  margin-top: 100px;
  width: 100%;
}

.runBtns {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.runBtns > img {
  margin-top: 17px;
  cursor: pointer;
}
.consoleBox button {
  padding: 5px 15px;
  border-radius: 10px;
  border: none;
  border-radius: 5px;
  float: right;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  margin-right: 5%;
  margin-top: 19px;
  font-weight: 500;
  background: #008405;
}
.consoleBox span {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-style: normal;
  margin-left: 30px;
  font-weight: 500;
  margin-top: 9px;
}
.consoleBoxUp span {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  margin-top: 9px;
  font-style: normal;
  font-weight: 500;
  margin-left: 30px;
}

.arrow {
  color: black;
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
  padding-left: 150px;
}

/* Buttons */

.dontknow {
  background-color: rgb(188, 165, 38);
  height: 40px;
  padding: 0px 20px;
  margin: auto 50px;
  border: none;
  border-radius: 5px;
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-style: normal;
  margin-top: -60px;
  font-weight: 500;
}

.enablebtn {
  background-color: rgb(188, 165, 38);
  height: 40px;
  padding: 0px 20px;
  margin: auto 50px;
  border: none;
  border-radius: 5px;
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-style: normal;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
}
.disablebtn {
  background-color: rgb(175, 174, 174);
  height: 40px;
  padding: 0px 20px;
  margin: auto 50px;
  border: none;
  border-radius: 5px;
  color: rgb(38, 37, 37);
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-style: normal;
  margin-top: 10px;
  cursor: not-allowed;
}

.toastalign {
  position: absolute;
  right: 0;
  bottom: 70px;
  background-color: #ffffffd9;
  color: black;
}

.confirmandnext {
  border-style: none;
  background-color: green;
  color: black;
}
@media (max-width: 900px) {
  .questionNo h3 {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
  }

  .cameraBox {
    /* height: 45vh; */
    border-radius: 20px;
    background: #d9d9d9;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    object-fit: contain;
    width: 300px;
  }
  .questionNo h3 > span {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .outerContainer {
    background-color: #f2f7ff;
    width: 100%;
    overflow-y: auto;
    height: auto;
  }
  .questionNo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0px 5px;
    margin: auto;
    background-color: #fff;
  }

  .theoritical {
    background-color: #ffffff;
    margin: auto;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70vh;
  }
  .leftside {
    display: flex;
    width: 100%;
    justify-content: center;
    border-style: none;
  }

  .questionNo h3 > span {
    font-size: 13px;
    font-weight: 600;
  }
  .cameraBox {
    width: 70%;
    height: 400px;
    border-radius: 20px;
    background: #d9d9d9;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    object-fit: contain;
  }
  .warning {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
  }
  .option p {
    font-size: 18px;
  }
  .theoritical p {
    color: #5b5858;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 10px auto 20px auto;
  }
  .mainCameraBox {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .warning h4 {
    color: #fe0000;
    font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 10px auto;
    width: 60%;
    text-align: center;
  }
  /* Buttons */
  .option input[type="radio"]:checked ~ .design {
    height: 18px;
  }
  .radio_main > input[type="radio"]:checked ~ .value {
    font-size: 15px;
    margin-left: -5px;
  }
  .design {
    height: 8px;
  }
  .dontknow {
    background-color: rgb(188, 165, 38);
    height: 40px;
    padding: 0px 25px;
    margin: auto 50px;
    border: none;
    border-radius: 5px;
    color: black;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    margin-top: -60px;
    font-weight: 500;
  }

  .enablebtn {
    background-color: rgb(188, 165, 38);
    height: 37px;
    padding: 0px 13px;
    margin: auto 10px;
    border: none;
    border-radius: 5px;
    color: black;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    margin-top: 10px;
    cursor: pointer;
    position: static;
  }
  .disablebtn {
    background-color: rgb(175, 174, 174);
    height: 37px;
    padding: 0px 10px;
    margin: auto 10px;
    border: none;
    border-radius: 5px;
    color: rgb(38, 37, 37);
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    margin-top: 10px;
    cursor: not-allowed;
  }
  .questionNo h3 {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: auto;
  }
  .value {
    width: 95%;
    margin-right: 10px;
  }
  .option div {
    display: flex;
    justify-content: space-around;
    width: 95%;
    align-items: flex-start;
  }
  .question {
    width: 95%;
    height: auto;
    min-height: 180px;
    min-height: auto;
    overflow: auto;
    margin-top: 10px;
    border-right: none;
  }
  .mainQuestion {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin: auto;
    min-height: auto;
    height: auto;
    flex-direction: column;
  }
  .question p {
    color: #5b5858;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 10px auto;
    text-align: center;
  }
  .answer {
    width: 95%;
    min-height: auto;
    height: auto;
    margin-top: -10px;
  }
  .radio_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;
    cursor: pointer;
    font-size: 15px;
    width: 100%;
    color: #8b8c8c;
  }
  .PassageRadio_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;
    cursor: pointer;
    font-size: 20px;
    color: #8b8c8c;
  }
  .option {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: baseline;
    align-items: baseline;
    margin: auto;
  }
}
