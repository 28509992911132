@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.section {
  opacity: 0;
  transform: translateY(0px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}
#subHeading {
  font-size: 40px;
  font-family: "Roboto";
  font-weight: 500;
  width: 55%;
  margin: auto;
  text-align: center;
  margin-top: 100px;
  /* margin-bottom: 50px; */
}
.section.animate {
  opacity: 1;
  transform: translateY(0);
}

.sectionimage {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  position: relative;
}

.sectionimageleft {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  position: relative;
}

.sectionimageleft.imageanimate {
  opacity: 1;
  transform: translateX(0);
  z-index: 3;
  position: relative;
}

.sectionimage.imageanimate {
  opacity: 1;
  transform: translateX(0);
  z-index: 3;
  position: relative;
}

.sectionimage.imageanimateleft {
  opacity: 1;
  transform: translateX(0);
  z-index: 3;
  position: relative;
}

:root {
  --textColor: rgba(71, 157, 177, 1);
}

#newHome_component {
  font-family: "Roboto", sans-serif;
  padding: 10px;
  padding: 0;
  margin: 0;
}
/*----------------home secton-----------------*/
.home_main_Section {
  display: flex;
  width: 96%;
  margin: 90px auto auto auto;
  justify-content: space-between;
}
.home_main {
  width: 55%;
  height: auto;
  color: rgb(26, 0, 0);
  border-radius: 20px;
  animation: home_main 2s ease-out forwards;
}

@keyframes home_main {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.home_main_image {
  height: auto;
  border-radius: 20px;
  object-fit: cover;
}

.shadow_div {
  width: 50%;
}

.home_main_content_section {
  width: 100%;
  height: auto;
  font-size: 1.2vw;
  font-family: "Roboto";
  display: flex;
  padding: 20px;
  margin-top: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.home_main_content_section h1 {
  font-size: 40px;
  font-family: "Roboto";

  font-weight: 500;
}
.home_main_content_section > label {
  font-size: 19px;
  margin: 25px 0px auto 0px;
}
.home_main_content_section label > span {
  font-size: 19px;
  position: relative;
  background-color: white;
  padding: 5px;
  right: 12px;
}
.home_main_content_section h6 {
  font-size: 19px;
  font-family: "Roboto";
  margin: 40px 0px;
}
.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.home_main_content_section h2 {
  font-size: 25px;
  font-family: "Roboto";
  margin: 15px 0px;
}
.signup_button {
  width: 15vw;
  height: 50px;
  margin-top: 10px;
  background-color: #1e3a8a;
  border-style: none;
  border-radius: 5px;
  font-family: "Roboto";
  color: white;
  font-size: 1.5vw;
  margin-left: 0px;
}
.home_main_left {
  font-family: "Roboto";
  width: 40%;
  overflow: hidden;
}
.home_main_left > img {
  object-fit: cover;
  height: 75%;
  width: 80%;
  border-radius: 37% 63% 43% 57% / 64% 35% 65% 36%;
}
.findCourses {
  width: 100%;
  height: 23vh;
  background-image: linear-gradient(
    89.84deg,
    #57bfd4 0.11%,
    #2f393b 65.94%,
    #007793 99.85%
  );
  position: relative;
  border-radius: 20px;
  opacity: 0;
  animation: fade 1s ease-out forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.findCourses_image {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.checkskills_section {
  width: 100%;
  height: 23vh;
  background: linear-gradient(
    89.84deg,
    #5cc9de 0.11%,
    #000418 45.48%,
    #888888 99.85%
  );
  position: relative;
  border-radius: 20px;
  opacity: 0;
  animation: fade1 1s ease-out forwards;

  /* transition: opacity 0.5s ease-out; */
}

@keyframes fade1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.checkSkills_image {
  width: 55%;
  height: 100%;
  position: absolute;
  right: 0;
  border-radius: 20px;
}

.customizeTest {
  width: 100%;
  height: 23vh;
  background: linear-gradient(
    89.84deg,
    #daeaff 0.11%,
    #dae7f8 49.98%,
    #000916 99.85%
  );
  position: relative;
  border-radius: 20px;
  opacity: 0;
  animation: fade2 1s ease-out forwards;

  /* transition: opacity 1s ease-out; */
}

@keyframes fade2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.customizeTest_image {
  width: 60%;
  height: 100%;
  position: absolute;
  right: 0;
  border-radius: 20px;
}

.customizeTest_heading {
  position: absolute;
  font-size: 1.5vw;
  font-family: "Roboto";
  left: 30px;
  top: 20px;
  width: 60%;
}
.arrowIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  left: 30px;
  background-color: white;
  border-radius: 50%;
}

/*----------------company secton-----------------*/
.company_logo_section {
  overflow: hidden;
  font-family: "Roboto";
  margin-top: -100px;
}
.company_logo_section > h6 {
  font-size: 21px;
  font-family: "Roboto";
  margin-top: 10px;
  padding: 0 10px;
  text-align: center;
}
.companies_title {
  text-align: center;
  margin: 0px 0px 15px 0px;
  font-family: "Roboto";
  font-size: 35px;
}

.company_logo {
  margin-top: 85px;
  display: flex;
  margin-bottom: 40px;
  width: calc(250px * 12); /* Width of a single slide times number of slides */
  animation: scroll 30s linear infinite;
}

.slide1 {
  width: 130px;
  height: 130px;
  margin-right: 40px;
  border-radius: 10px;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 6)); /* Move by the width of 6 slides */
  }
}

/*----------------courses secton-----------------*/
.all_courses_section {
  background: rgba(249, 250, 251, 0.7);
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0px;
  margin-top: 20px;
}
.all_courses_section p {
  color: var(--textColor);
  font-size: 16px;
  margin: 0;
  font-family: "Roboto";
  padding: 0;
  text-align: center;
}
.all_courses_section h3 {
  font-size: 25px;
  text-align: center;
  margin-top: 5px;
  font-family: "Roboto";
}

.courseItem {
  width: 100%;
  height: 80px;
  border: 1px solid rgba(211, 211, 211, 1);
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: white;
}

.courseItem .course_Name {
  font-size: 1vw;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}

.course_logo {
  width: 35px;
  height: 35px;
  /* border:1px solid red */
}
.all_courses {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 50px;
  margin-bottom: 40px;
}

.explore_button {
  background: var(--textColor);
  border-style: none;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  margin-top: 30px;
}

.explore_button:hover {
  background-color: rgba(16, 19, 20, 0.5);
}

/*-------mock dashboard----------*/
.mock_dashboard {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  font-size: 16px;
  position: sticky;
  font-family: "Roboto";
}

.mock_dashboard > div > h5 {
  text-align: center;
  font-size: 20px;
  margin: 10px auto 40px auto;
  font-family: "Roboto";
  color: #228fda;
}

.mock_dashboard > h2 {
  font-family: "Roboto";
  padding: 5px 0 0 0;
  margin: 0;
  text-align: center;
  font-size: 18px;
}

#slogan {
  font-size: 18px;
  text-align: center;
  width: 80%;
  font-family: "Roboto";
  margin: auto;
  padding: 20px 50px 0 50px;
}
.home_left_heading1 {
  font-size: 25px;
  font-family: "Roboto";
  font-weight: 500;
  margin-top: 20px;
}
.demo_section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 10px;
  width: 70%;
  height: auto;
  margin: 20px auto auto auto;
}
.demoHead {
  font-size: 25px !important;
  text-align: left;
}
.left_side {
  width: 50%;
  height: 65%;
  position: relative;
}
.left_side > h3 {
  font-size: 25px;
  text-align: left;
}
.try_it {
  color: rgb(97, 49, 49);
  margin-left: 50x;
  font-size: 17px;
  margin-top: -10px;
  font-family: "Roboto";
  color: #228fda;
}

.dashboard_img_section {
  width: 30vw;
  height: 55vh;
  position: relative;
}
.dashboard_img {
  width: 60%;
  height: 65%;
  border-radius: 10px;
}
#mockToolPractice_img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: -10px;
  object-fit: contain;
  z-index: 1;
}
.onlineTraining_img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.img_transparent_border {
  position: absolute;
  border: 5px solid rgba(255, 255, 255, 0.3);
  width: 46%;
  height: 77%;
  border-radius: 10px;
}
.connectingPeople_img {
  width: 48%;
  height: 80%;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4); */
  border-radius: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.img_transparent_border1 {
  position: absolute;
  border: 5px solid rgba(255, 255, 255, 0.3);
  width: 46%;
  height: 77%;
  border-radius: 10px;
  right: 0;
  bottom: 0;
}

.revisingThings_img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  position: absolute;
  left: 0px;
}
.groupDissussions_img {
  width: 48%;
  height: 80%;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4); */
  border-radius: 10px;
  position: absolute;
  top: -10px;
  right: 0px;
}

.revisingThings_border {
  position: absolute;
  border: 5px solid rgba(255, 255, 255, 0.3);
  width: 46%;
  height: 77%;
  border-radius: 10px;
  bottom: -10px;
  left: 0px;
}

.groupDissussions_border1 {
  position: absolute;
  border: 5px solid rgba(255, 255, 255, 0.3);
  width: 46%;
  height: 77%;
  border-radius: 10px;
  top: -10px;
  right: 0px;
}

.revisingThings_section {
  width: 50%;
  /* border:1px solid red; */
  height: 60px;
  position: absolute;
  left: 5%;
  top: 10px;
  display: flex;
  flex-direction: column;
  padding: 3px 10px;
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: 16px 2px 26px -3px;
  z-index: 2;
}

.groupDissussions_details_section {
  width: 50%;
  /* border:1px solid red; */
  height: 60px;
  position: absolute;
  right: 5%;
  /* bottom:10px; */
  top: 270px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: 16px 2px 26px -3px;
  z-index: 2;
}

.weeklyPracticeReport_img {
  width: 60%;
  height: 50%;
  border-radius: 10px;
  position: absolute;
  right: 0px;
  bottom: -10px;
  box-shadow: 5px 10px 26px -3px;
}
.groupDissussions_details_section h3 {
  margin: 0;
  padding: 0;
  font-size: 8px;
  width: 100%;
  font-family: "Roboto";
}
.groupDissussions_details_section p {
  margin: 0;
  padding: 7px 0 0 0;
  font-family: "Roboto";
  font-size: 6px;
}

.left_side li {
  margin-top: 10px;
}
.left_side li > h3 {
  text-align: left;
  font-size: 16px;
  font-family: "Roboto";
}

.right_side_details_section {
  width: 50%;
  /* border:1px solid red; */
  height: 60px;
  position: absolute;
  right: 10%;
  top: 10px;
  display: flex;
  gap: 20px;
  padding: 10px;
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: 16px 2px 26px -3px;
  z-index: 2;
}

.left_side_details_section {
  width: 50%;
  /* border:1px solid red; */
  height: 60px;
  position: absolute;
  left: 10%;
  top: 250px;
  display: flex;
  gap: 20px;
  padding: 10px;
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: 16px 2px 26px -3px;
}

.jobInterviewIcon_Icon {
  width: 40px;
  height: 40px;
}

.jobInterviewIcon_slogan {
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-family: "Roboto";
  font-size: 0.6vw;
}

.left_side_details_rating_section {
  width: 50%;
  height: 50px;
  position: absolute;
  left: 10%;
  top: 290px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  align-items: center;
  font-family: "Roboto";
  background: white;
  border-radius: 10px;
  box-shadow: 16px 2px 26px -3px;
}

.range_side_details_section {
  width: 50%;
  /* border:1px solid red; */
  height: 60px;
  position: absolute;
  right: 2%;
  top: 10px;
  display: flex;
  flex-direction: column;
  padding: 3px 10px;
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: 16px 2px 26px -3px;
  z-index: 2;
}

.range_left_side_details_section {
  width: 50%;
  /* border:1px solid red; */
  height: 60px;
  position: absolute;
  left: 2%;
  top: 250px;
  display: flex;
  flex-direction: column;

  /* gap:20px; */
  padding: 3px 10px;
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: 16px 2px 26px -3px;
  z-index: 2;
}
.inner_container1 {
  width: 100%;
  /* border:1px solid red; */
  height: 80px;
}

.inner_container1_section1 {
  width: 95%;
  /* border:1px solid red; */
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: auto;
}

.progress_percentage {
  width: 40px;
  height: 15px;
  font-family: "Roboto";
  border-radius: 20px;
  color: white;
  font-size: 10px;
  background: #12e300;
  text-align: center;
}

.container_title {
  font-size: 10px;
  color: #8d8888;
  font-family: "Roboto";
  text-align: bottom;
}
.inner_container1_section2 {
  width: 98%;
  background: linear-gradient(
    90deg,
    #12e300 0%,
    #12e300 61.4%,
    #ffffff 80.4%,
    #ffffff 100%
  );

  height: 10px;
  border-radius: 20px;
  border: 0.5px solid #d0d0d0;
  margin-top: 5px;
}

.connectingPeople_slogan {
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-family: "Roboto";
  width: 100%;
  font-size: 12px;
  /* border:1px solid red; */
}

.star_showcase {
  /* border:1px solid red; */
  width: 100%;
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
}
.checked {
  color: orange;
}
.faq_section {
  width: 100%;
  height: 500px;
}
/* Footer Styles starts from here */
.footerMain {
  height: auto;
  width: 100%;
  background-color: white;
  display: flex;
  padding: 0px 45px;
  justify-content: space-between;
  font-family: "Roboto";
}
.brandLogo {
  width: 35%;
  padding-left: 15px;
}
.brandLogo > label {
  width: 80%;
}
.brandLogo > img {
  margin-left: -10px;
}
.privacyPolicy {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 00px 20px 20px;
}
.privacyPolicy > label {
  width: 100%;
  margin: 10px 0px;
  padding: 0px 10px;
  font-size: 16px;
}
.privacyPolicy > label > span {
  margin: 10px 0px;
  font-weight: 700;
  font-size: 21px;
}
.socialMedia {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: last baseline;
  padding: 12px 00px 20px 20px;
}
.socialMedia > label {
  width: 100%;
  margin: 10px 0px;
  padding: 0px 10px;
  font-size: 16px;
}
.socialMedia > label > span {
  margin: 10px 0px;
  font-weight: 700;
  font-size: 21px;
}

.socialBox {
  width: 91%;
  height: 90px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}
.leftSocial {
  display: flex;
  align-items: center;
  font-family: "Roboto";
}
.rightSocial {
  display: flex;
  align-items: center;
}
.leftSocial > h6 {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  margin: auto 10px auto 0px;
}
.rightSocial > h6 {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  margin: auto 10px auto 0px;
}
.leftSocial > label {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}
.rightSocial > label {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}
.leftSocial > label > span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  color: white;
  margin-right: 15px;
  background-color: #000418;
}
.rightSocial > div > label > span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  color: white;
  margin-right: 5px;
  background-color: #000418;
}
@media only screen and (max-width: 1000px) {
  .home_main_Section {
    padding: 0 10px 0px 9px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 700px) {
  .home_main_Section {
    padding: 0 0px 0px 0px;
    flex-direction: column;
    align-items: center;
    /* border:1px solid red; */
  }

  .home_main {
    width: 90%;
    height: 300px;
    position: relative;
    /* border:1px solid blue */
  }
  #subHeading {
    font-size: 35px;
    font-family: "Roboto";
    width: 75%;
    font-weight: 500;
    text-align: center;
  }
  .home_main_content_section {
    position: absolute;
    width: 65%;
    height: 150px;
    left: 30px;
    font-family: "Roboto";
    top: 50px;
    font-size: 16px;
    gap: 50px;
  }

  .signup_button {
    width: 35%;
    height: 37px;
    background: var(--textColor);
    border-style: none;
    border-radius: 5px;
    font-family: "Roboto";
    color: white;
    font-size: 2.2vw;
  }
  .home_main_left {
    display: flex;
    /* flex-direction:row; */
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 550px;
    /* padding-top:px; */
    /* border:1px solid red; */
    gap: 15px;
  }

  .customizeTest_heading {
    position: absolute;
    font-size: 3vw;
    left: 15px;
    top: 0px;
    font-family: "Roboto";
    width: 60%;
  }

  .findCourses {
    width: 100%;
    height: 10em;
    /* max-height:150px; */
    background-image: linear-gradient(
      89.84deg,
      #57bfd4 0.11%,
      #2f393b 65.94%,
      #007793 99.85%
    );
    position: relative;
    border-radius: 20px;
  }

  .checkskills_section {
    width: 100%;
    height: 10em;
    background: linear-gradient(
      89.84deg,
      #5cc9de 0.11%,
      #000418 45.48%,
      #888888 99.85%
    );
    position: relative;
    border-radius: 20px;
  }

  .customizeTest {
    width: 100%;
    height: 10em;
    background: linear-gradient(
      89.84deg,
      #daeaff 0.11%,
      #dae7f8 49.98%,
      #000916 99.85%
    );
    position: relative;
    border-radius: 20px;
  }

  /*all courses section*/
  .all_courses_section {
    background: rgba(249, 250, 251, 0.7);
    width: 100%;
    height: 650px;
    /* border:1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .all_courses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    /* border:1px solid green; */
    width: 70%;
  }

  .courseItem {
    width: 80%;
    height: 80px;
    border: 1px solid rgba(211, 211, 211, 1);
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: white;
  }

  .courseItem .course_Name {
    font-size: 2.2vw;
    font-family: "Roboto";
    margin: 0;
    padding: 0;
  }

  /*company animation*/
  .slide {
    width: 120px;
    height: 120px;
    margin-right: 50px;
    border-radius: 10px;
    object-fit: contain;
  }
}

/*faq section*/
.faq_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 45px;
  background-color: #4fb1f3;
  /* border: 1px solid #ddd; */
  /* max-width: 1200px; */
  margin: 50px auto;
  gap: 20px;
}

.faq_left {
  /* border:1px solid green; */
  flex: 1;
  min-width: 280px;
  margin: 10px;
}

.faq_right {
  /* border:1px solid green; */
  flex: 1;
  max-width: 400px;
  min-width: 300px;
  margin: 10px;
  padding: 20px 0 0 20px;
}

.faq_item {
  background: rgba(241, 241, 241, 1);
  padding: 15px 15px 15px 25px;
  margin-top: 18px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq_question {
  font-weight: 500;
  font-family: "Roboto";
  font-size: 19px;
}

.faq_toggle {
  font-size: 24px;
  font-family: "Roboto";
  cursor: pointer;
}

.faq_right h2 {
  margin-bottom: 10px;
  font-size: 35px;
  font-family: "Roboto";
  color: white;
  font-weight: 500;
}

.faq_description {
  font-size: 20px;
  font-family: "Roboto";
  margin-bottom: 20px;
  color: rgb(255, 255, 255);
}

.contact_button {
  padding: 10px 20px;
  background: white;
  color: #220dd8;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.contact_button:hover {
  background: #220dd8;
  color: white;
  font-weight: 500;
}

/* mobile view css*/

@media only screen and (max-width: 500px) {
  .home_main_Section {
    padding: 0 0px 0px 0px;
    flex-direction: column;
    align-items: center;
  }
  #button {
    width: 80%;
    font-size: 17px;
  }
  .home_main_content_section > label {
    font-size: 19px;
    margin: 25px 0px auto 14px;
  }
  .home_main_content_section label > span {
    font-size: 19px;
    position: relative;
    background-color: white;
    padding: 5px;
    right: 12px;
  }
  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: auto;
  }
  .home_main_left > img {
    display: none;
  }
  .faq_item {
    padding: 8px 10px 8px 15px;
    margin-top: 10px;
    border-radius: 7px;
  }
  .contact_button {
    margin-top: 15px;
  }
  .faq_description {
    font-size: 17px;
    margin-bottom: 0px;
  }
  .faq_question {
    font-weight: 400;
    font-family: "Roboto";
    font-size: 15px;
  }
  .footerMain {
    height: auto;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Roboto";
    padding: 0px 45px 20px 10px;
  }
  .brandLogo {
    width: 95%;
    padding: 20px;
    margin: 0px auto;
  }
  .brandLogo > button {
    margin-left: -0px;
  }
  .brandLogo > label {
    width: 100%;
    font-size: 14px;
  }
  .privacyPolicy {
    width: 100%;
  }
  .privacyPolicy > label {
    font-size: 13px;
  }
  .privacyPolicy > label > span {
    font-size: 18px;
  }
  .socialMedia {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .socialMedia > label {
    font-size: 14px;
  }
  .socialMedia > label > span {
    font-size: 19px;
    padding-top: 0px;
  }
  .socialBox {
    height: auto;
    width: 100%;
    padding: 10px;
  }
  .leftSocial {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: flex;
    font-size: 13px;
  }
  .rightSocial {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .leftSocial > h6 {
    font-size: 19px;
    display: none;
    font-weight: 500;
    margin: auto 5px auto 0px;
  }
  .rightSocial > h6 {
    font-size: 19px;
    font-weight: 500;
    margin: auto 5px auto 0px;
  }
  .leftSocial > label {
    margin: 5px;
  }
  .rightSocial > div {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }
  .leftSocial > label > span {
    margin-right: 5px;
    padding: 7px;
  }
  .rightSocial > label > span {
    margin: 10px 0px;
    padding: 7px;
  }
  .company_logo_section {
    margin-top: 10px;
  }
  .home_main_Section {
    width: 100%;
  }
  #newHome_component {
    margin-top: 40px;
  }
  .left_side li > p {
    text-align: left;
    font-size: 16px;
    font-family: "Roboto";
  }

  .left_side li > h3 {
    text-align: left;
    font-weight: 400;
    font-size: 15px;
    font-family: "Roboto";
  }
  #subHeading {
    font-size: 25px;
    font-family: "Roboto";
    font-weight: 500;
    text-align: center;
    width: 95%;
    margin: 30px auto 0px auto;
    padding-top: 0px;
  }
  .home_main {
    width: 100%;
    height: auto;
  }
  .home_main_content_section {
    width: 95%;
    height: auto;
    font-family: "Roboto";
    font-size: 12px;
    margin-top: 2px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: auto;
  }

  .home_main_content_section h1 {
    font-size: 28px;
    font-family: "Roboto";
    text-align: center;
    margin: 0px auto 20px auto;
  }
  .faq_container {
    margin-bottom: 10px;
  }
  .home_main_content_section h2 {
    font-size: 19px;
    font-family: "Roboto";
  }

  .home_main_content_section h6 {
    font-size: 16px;
    font-family: "Roboto";
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .signup_button {
    width: 46%;
    height: 45px;
    margin: 35px auto 0px auto;
    border-style: none;
    border-radius: 5px;
    font-family: "Roboto";
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
  }
  .home_main_left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: auto;
    font-family: "Roboto";
    gap: 15px;
  }

  .customizeTest_heading {
    position: absolute;
    font-size: 13px;
    left: 15px;
    font-family: "Roboto";
    /* top:0px; */
    width: 60%;
  }

  .arrowIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  /* Company Section */
  .companies_title {
    font-size: 16px;
    font-family: "Roboto";
  }

  .company_logo_section > h6 {
    font-size: 14px;
    font-family: "Roboto";
    margin-top: 10px;
    padding: 0 10px;
    text-align: center;
  }

  .findCourses {
    width: 100%;
    height: 8em;
    /* max-height:150px; */
    background-image: linear-gradient(
      89.84deg,
      #57bfd4 0.11%,
      #2f393b 65.94%,
      #007793 99.85%
    );
    position: relative;
    border-radius: 20px;
  }

  .checkskills_section {
    width: 100%;
    height: 8em;
    background: linear-gradient(
      89.84deg,
      #5cc9de 0.11%,
      #000418 45.48%,
      #888888 99.85%
    );
    position: relative;
    border-radius: 20px;
  }

  .customizeTest {
    width: 100%;
    height: 8em;
    background: linear-gradient(
      89.84deg,
      #daeaff 0.11%,
      #dae7f8 49.98%,
      #000916 99.85%
    );
    position: relative;
    border-radius: 20px;
  }

  /*all courses section*/
  .all_courses_section {
    background: rgba(249, 250, 251, 0.7);
    width: 100%;
    height: auto;
    padding: 15px 0px;
    /* border:1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap:20px; */
    /* border:1px solid green; */
  }

  .all_courses_section > center > h2 {
    font-size: 16px;
    font-family: "Roboto";
  }

  .all_courses_section > center > h3 {
    font-size: 13px;
    font-family: "Roboto";
  }

  .all_courses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;

    width: 80%;
  }
  .explore_button {
    background: var(--textColor);
    border-style: none;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    margin-top: 25px;
    font-size: 15px;
    font-family: "Roboto";
  }
  .courseItem {
    width: 100%;
    height: 100px;
    border: 1px solid rgba(211, 211, 211, 1);
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: white;
  }

  .courseItem .course_Name {
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-family: "Roboto";
  }

  /*company animation*/
  .slide {
    width: 100px;
    height: 100px;
    margin-right: 50px;
    border-radius: 10px;
    object-fit: contain;
  }

  /*faq section*/
  .faq_container {
    gap: 0px;
  }

  .faq_right {
    padding: 0px 0 0 0px;
  }

  /*-------mock dashboard----------*/
  .mock_dashboard {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    font-size: 16px;
    display: flex;
    font-family: "Roboto";
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border:1px solid yellow; */
  }

  .mock_dashboard h5 {
    text-align: center;
    margin-bottom: 0px;
  }
  .mock_dashboard > div > h5 {
    text-align: center;
    margin-bottom: 0px;
    width: 90%;
    margin: auto
  }

  .mock_dashboard h2 {
    padding: 5px 0 0 0;
    margin: 0;
    font-family: "Roboto";
    font-size: 22px;
  }
  .mock_dashboard h3 {
    text-align: center;
    font-family: "Roboto";
    padding: 5px 0 0 0;
    margin: 10px 0px;
    font-size: 14px;
  }

  .mock_dashboard #slogan {
    font-size: 0.8rem;
    text-align: center;
    width: 100%;
    font-family: "Roboto";
    margin: auto;
    padding: 10px 10px;
  }

  .demo_section {
    display: flex;
    flex-wrap: wrap-reverse;
    width: 90%;
    height: auto;
    padding-top: 0px;
    margin-top: 10px;
  }

  .demo_section .left_side {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 20px;
  }

  .try_it {
    font-family: "Roboto";
    color: rgb(69, 67, 221);
    font-weight: 400;
    font-size: 15px;
    margin-top: -5px;
    margin-bottom: 20px;
    margin-left: 5px;
  }

  .left_side h3 {
    width: 100%;
    font-size: 22px;
    text-align: left;
    font-family: "Roboto";
  }

  .dashboard_img_section {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .dashboard_img {
    width: 80%;
    height: 30%;
    border-radius: 10px;
  }
  .revisingThings_img {
    width: 48%;
    height: 70%;
    border-radius: 10px;
    position: absolute;
    bottom: -10px;
    left: 0px;
  }
  #mockToolPractice_img {
    width: 90%;
    position: static;
    margin: 45px auto 10px auto;
  }
  .groupDissussions_img {
    width: 48%;
    height: 70%;
    border-radius: 10px;
    position: absolute;
    top: -10px;
    right: 0px;
  }
  .company_logo {
    margin-top: 25px;
    margin-bottom: 0px;
  }
  .onlineTraining_img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 40px;
    object-fit: fill;
  }
  .img_transparent_border {
    position: absolute;
    border: 5px solid rgba(255, 255, 255, 0.3);
    width: 46%;
    height: 65%;
    border-radius: 10px;
  }
  .connectingPeople_img {
    width: 48%;
    height: 70%;
    border-radius: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .img_transparent_border1 {
    position: absolute;
    border: 5px solid rgba(255, 255, 255, 0.3);
    width: 46%;
    height: 77%;
    border-radius: 10px;
    right: 0;
    bottom: 0;
  }
  .jobInterviewIcon_Icon {
    width: 20px;
    height: 20px;
  }

  .jobInterviewIcon_slogan {
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 1.6vw;
    font-family: "Roboto";
  }
  .left_side_details_section {
    height: 45px;
    position: absolute;
    left: 10%;
    top: 150px;
  }

  .right_side_details_section {
    height: 45px;
    right: 5%;
    top: 2px;
  }

  .range_side_details_section {
    width: 60%;
    height: 40px;
    right: 5%;
    top: 5px;
    display: flex;
    flex-direction: column;
    padding: 3px;
    align-items: center;
    background: white;
    border-radius: 10px;
    box-shadow: 16px 2px 26px -3px;
    z-index: 2;
  }

  .range_left_side_details_section {
    width: 60%;
    height: 40px;
    position: absolute;
    top: 150px;
    display: flex;
    flex-direction: column;
    padding: 3px;
    align-items: center;
    background: white;
    border-radius: 10px;
    box-shadow: 16px 2px 26px -3px;
    z-index: 2;
    left: 5%;
    bottom: 10px;
  }

  .revisingThings_section {
    width: 60%;
    height: 40px;
    display: flex;
    flex-direction: column;
    padding: 3px;
    align-items: center;
    background: white;
    border-radius: 10px;
    box-shadow: 16px 2px 26px -3px;
    z-index: 2;
    left: 5%;
    top: 10px;
  }
  .groupDissussions_details_section {
    width: 65%;
    height: 90px;
    right: 0%;
    top: 150px;
  }

  .left_side_details_rating_section {
    width: 70%;
    height: 30px;
    position: absolute;
    left: 5%;
    top: 150px;
  }
  .connectingPeople_slogan {
    font-size: 2vw;
    font-family: "Roboto";
  }
  .inner_container1 {
    width: 100%;
    height: 50px;
  }
  .inner_container1_section1 {
    width: 95%;
    height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: auto;
  }
  .progress_percentage {
    width: 30px;
    height: 8px;
    border-radius: 20px;
    color: white;
    font-family: "Roboto";
    font-size: 6.5px;
    background: #12e300;
    text-align: center;
  }
  .container_title {
    font-size: 5px;
    font-family: "Roboto";
    color: #8d8888;
    text-align: bottom;
  }
  .inner_container1_section2 {
    height: 3px;
  }
  .left_side li {
    margin-top: 10px;
  }
}
