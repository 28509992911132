@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Arvo&family=Poppins&display=swap");

.main {
  background-image: radial-gradient(white, #c4f1f2, #7b94f4);
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: auto;
}
.container {
  width: 35%;
  max-width: 90%;
  background-color: #fff;
  height: auto;
  margin: auto;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
}
.rightSidebar {
  background: #f4f4f4;
  width: 310px;
  height: 530px;
  border-radius: 10px;
  text-align: center;
}
.rightImage img {
  width: 220px;
  height: 183px;
}
.rightImage {
  margin-top: 74px;
}
.first {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.second {
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #999;
}
.rightSidebarTxt {
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #999;
  margin-top: 10px;
}
.dot_sub1 {
  width: 7px;
  height: 7px;
  background: #08213e;
  border-radius: 7px;
  margin-right: 3px;
}
.dot_sub2 {
  width: 7px;
  height: 7px;
  background: #aaa;
  border-radius: 7px;
  margin-right: 3px;
}
.Dot {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar img {
  height: 80px;
}
.navbar span {
  font-family: Arvo;
  color: #000;
  font-family: Arvo;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftSidebar {
  width: 100%;
  height: auto;
  padding: 20px 10px;
}
.rightSidebarContext {
  width: 100%;
}
.horn {
  display: flex;
  align-items: center;
  width: 80%;
  margin: auto;
  border-radius: 10px;
  border: 1px solid #e8e7e7;
  background: #fff;
  text-decoration: none;
}
.hand {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 20px auto;
  border-radius: 10px;
  border: 1px solid #e8e7e7;
  background: #fff;
  text-decoration: none;
}
.Mock {
  color: #000;
  font-family: Poppins;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.rTxt {
  color: #999;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  text-align: center;
  margin-top: 5px;
}

.hornTxt {
  margin-left: 10px;
  margin-top: 10px;
}
.label1 {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: -20px;
}
.label2 {
  color: #848484;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.horn img {
  width: 30px;
  margin-left: 10px;
}
.hand img {
  width: 30px;
  margin-left: 10px;
}
.handTxt {
  margin-left: 10px;
  margin-top: 10px;
}
.btnDiv {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto;
}
.btn {
  padding: 8px 20px;
  border-radius: 10px;
  border: none;
  color: white;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #08213e;
}
.btnDiv a {
  margin-left: 40px;
  color: #000;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.moveBar {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  margin-bottom: 20px;
}
.oneHalf {
  background-color: blue;
  width: 170px;
  background: #08213e;
  border-radius: 10px;
  margin-right: 2px;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    #08213e 0%,
    #08213e 26.04%,
    #cee8ff 26.05%
  );
}
.secondHalf {
  height: 3px;
  border-radius: 20px;
}
.horn:hover {
  cursor: pointer;
  box-shadow: 0 0 2px 2px rgb(247, 234, 234);
  border: 1px solid #cee8ff;
}
.hand:hover {
  cursor: pointer;
  box-shadow: 0 0 2px 2px rgb(247, 234, 234);
  border: 1px solid #cee8ff;
}

.descriptiontext {
  align-items: center;
  width: 200px;
}

/* Free  Trial */

hr {
  width: 240px;
  background-color: red;
  margin-bottom: 5px;
}
.freeTrial {
  margin-left: 30px;
  margin-top: 72px;
}
.freeTrial img {
  width: 18px;
}
.freeTrial label {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 15px;
}

.trial {
  font-size: 27px;
  color: #000;
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.trailHr {
  background: #04cb18;
  width: 240px;
  margin-bottom: 5px;
  height: 3px;
}

.rightSidebarTrial {
  background: #f4f4f4;
  width: 310px;
  height: 530px;
  border-radius: 10px;
  text-align: left;
}

/* Free Account */
.account {
  font-size: 27px;
  color: #000;
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.freeTrial {
  margin-left: 30px;
  margin-top: 72px;
}
.freeTrial img {
  width: 18px;
}
.freeTrial label {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 15px;
}

.accountHr {
  background: #04cb18;
  width: 240px;
  margin-bottom: 5px;
  height: 3px;
}

/* Screen below 650px */
@media only screen and (max-width: 650px) {
  .container {
    width: 90%;
    margin: 50px auto;
  }
  .rightSidebar,
  .rightSidebarTrial {
    display: none;
  }

  .leftSidebar {
    width: 90%;
    margin: auto;
  }
  .rightSidebarContext {
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  .Mock {
    width: 100%;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    text-align: center;

    font-weight: 600;
    line-height: 40px;
    margin-top: 0px;
  }
  .navbar {
    width: 100%;
    margin: auto;
  }
  .hand,
  .horn {
    width: 100%;
  }
  .btnDiv {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .btnDiv a {
    margin-left: 0;
  }
}

.alreadyaccount {
  font-size: 12px;
  cursor: pointer;
}

.alreadyaccount:hover {
  text-decoration: underline;
}
