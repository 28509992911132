.topBox {
  display: flex;
  justify-content: space-between;
  width: 95%;
  height: 50px;
  margin: auto;
  align-items: center;
}
.passage {
  width: 97%;
  margin: auto;
  padding: 10px;
  max-height: 350px;
  overflow-y: auto;
}
.detailsPassage {
  display: flex;
  flex-direction: column;
}
.detailsPassage > label {
  font-size: 16px;
  font-family: "Roboto";
  margin-bottom: 5px;
}
.detailsPassage > div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detailsPassage > div > h5 {
  min-width: 23%;
  padding: 6px;
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
}
.line {
  width: 85%;
}
.uploadfile {
  /* width: 200px; */
  height: 40px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  background-color: rgb(255, 255, 255);
  margin-left: 22px;
  margin-top: 10px;
  text-align: left;
}
.uploadfile:active {
  background-color: rgb(0, 47, 255);
  color: white;
}
.count {
  width: 95%;
  margin: 10px auto auto auto;
  height: auto;
  display: flex;
}
.count > label {
  font-size: 15px;
}
.selectBtn {
  width: 90%;
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectBtn > main {
  height: 35px;
  display: flex;
  align-items: center;
}
.selectBtn > main > label {
  margin-left: 10px;
  font-size: 18px;
  cursor: pointer;
}
.selectBtn > main > input {
  height: 20px;
  width: 20px;
  cursor: pointer;
  font-size: 18px;
}
.invalidQues {
  width: 95%;
  margin: 10px auto auto auto;
}
.invalidQues > label {
  color: red;
  font-size: 15px;
  margin: 10px auto;
}
.invalidQues > h6 {
  font-size: 17px;
  width: 100%;
  font-family: "sans-serif";
  line-height: 15px;
  font-weight: 500;
}
.box {
  display: flex;
  height: auto;
  width: 100%;
}
.navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 82%;
  position: fixed;
  z-index: 1;
  float: right;
  overflow: hidden;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
}
.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}
.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.rightNavbar {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.rightNavbar > div {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}
.navbarBox {
  height: 100vh;
  display: flex;
  width: 100%;
}
.topBox > p {
  font-size: 24px;
  color: #4caf50;
  font-weight: 400;
  margin-right: 100px;
}
.questionselect {
  width: 150px;
  height: 30px;
  border: none;
  border-radius: 5px;
  padding: 1px 5px;
  color: blueviolet;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px black;
}
.questionselect:focus {
  outline: none;
}
.addBtn {
  background-color: #ffffff;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: rgb(19, 17, 17);
  border-radius: 10px;
  border: 1px solid gray;
}
@media (max-width: 1200px) {
  .rightNavbar {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    height: 60px;
    padding: 10px 15px;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 1;
    left: 0;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .line {
    width: 100%;
  }
}

.edit_heading {
  font-weight: 500;
  font-family: "Poppins";
  font-size: 14;
}
.edit_option_selector {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
