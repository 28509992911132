@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Arvo&family=Poppins&display=swap");

.main {
  background-image: radial-gradient(white, #c4f1f2, #7b94f4);
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.container {
  width: auto;
  max-width: 800px;
  /* min-width: 500px; */
  background: #f4f4f4;
  height: 500px;
  margin: 15vh auto 0 auto;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  /* margin-top: 15vh; */
}

.leftSidebar {
  background: #ffffff;
  width: 310px;
  height: 500px;
  border-radius: 10px;
}

.rightSidebar {
  margin: 50px 120px 0 50px;
  background: #f4f4f4;
  width: 40%;
}

.carousel {
  width: 220px;
}
.images {
  text-align: center;
  margin-top: 10px;
}

.tool {
  text-align: center;
  margin-top: 20px;
}
.tool small {
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #999;
}

.first {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.second {
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #999;
}

.Dot {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.dot_sub1 {
  background: #08213e;
  border-radius: 50px;
  width: 7px;
  height: 7px;
  margin-right: 3px;
}
.dot_sub2 {
  background: #aaa;
  border-radius: 50px;
  width: 7px;
  margin-right: 3px;
  height: 7px;
}
.moveBar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.oneHalf {
  background-color: #08213e;
  width: 190px;
  border-radius: 2px;
  border-radius: 20px;
  /* background: linear-gradient(90deg, #08213E 0%, #08213E 26.04%, #CEE8FF 26.05%); */
}
.secondHalf {
  width: 7px;
  height: 3px;
  border-radius: 2px;
}
.navbar img {
  height: 50px;
  border-radius: 7px;
  margin: 12px 0px 0px 20px;
}

/* <------------------------------- media query -------------------> */

@media only screen and (max-width: 600px) {
  .leftSidebar {
    display: none;
  }
  .rightSidebar {
    padding: 15px;
    margin: 16vh 5% 0 5%;
    width: 90%;
  }
  .container {
    width: 85%;
    background-color: #fff;
    height: 500px;
    margin: 10vh auto;
    /* margin: auto; */
    /* margin: 0 20px 0 20px; */
    border-radius: 10px;
  }
}

.descriptiontext {
  align-items: center;
  width: 200px;
  height: 80px;
}

/* Left Side bar */

.heading {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subtitle {
  color: #999;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sendbtn {
  border-radius: 10px;
  border: 1px solid #ffc107;
  background: #ffc107;
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  color: #08213e;
  width: 120px;
}
.otpenter {
  width: 100%;
}
.password_validation {
  position: absolute;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  padding: 5px;
  border-radius: 10px;
}

.finalsubmit {
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #000;
  background: #08213e;
  color: white;
}
