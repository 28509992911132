@import url("https://fonts.googleapis.com/css2?family=Arvo&display=swap");
.btn_navbar {
  background-color: #08213e;
}
.btn_navbar:hover {
  background-color: #18406d;
}

.nav-items {
  font-size: 40px;
}

.company_title {
  color: #000;
  font-size: 40px;
  font-family: "Arvo", sans-serif;
  font-weight: 700;
  box-sizing: border-box;
}
.mynavbar {
  padding: 0 50px;
  height: 70px;
  background: #fff;
  /* box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25); */
  position: fixed;
  top: 0;
  width: 98.8%;
  left: 0;
  z-index: 1;
}

@media screen and (max-width: 500px) {
  .company_title {
    color: #000;
    font-size: 30px;
    font-family: "Arvo", sans-serif;
    font-weight: 700;
    box-sizing: border-box;
  }
  #company_logo {
    margin-left: -100px;
  }
  .mynavbar {
    padding: 0;
    margin-right: 10px;
  }
}

.mynavbar {
  background: #fff;
  /* box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25); */
  position: fixed;
  top: 0;

  z-index: 1;
}

.nav_title {
  color: #08213e;
  font-size: 15px;
  font-family: "DM Serif", sans-serif;
  font-weight: 500;
}
.nav_title:hover {
  cursor: pointer;
  color: #0053b4;
  font-size: 15px;
  font-family: "DM Serif", sans-serif;
  font-weight: 600;
}
.nav_img {
  width: 25px;
  height: 25px;
}

.nav_menu {
  margin-left: 20px;
  text-align: center;
}

.nav_menu:hover {
  cursor: pointer;
  color: #0053b4;
  font-size: 15px;
  font-family: "DM Serif", sans-serif;
  font-weight: 500;
  border: 1px solid black;
  border-radius: 5px;
}

#active {
  text-decoration-color: #0053b4;
  text-decoration: underline;

  .nav_title {
    color: #0053b4;
    font-weight: 600;
  }
}

.profile {
  cursor: pointer;
  font-weight: 600;
  position: relative;
}

.profile_menu {
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  justify-content: space-between;
  width: 80%;
  margin: 0px auto;
  text-align: center;
  font-size: 17px;
  font-family: "Poppins";
  background-color: white;
}
#logout {
  position: relative;
  top: 200px;
  width: 120px;
  margin: auto;
  left: 0;
  background-color: #57ca22;
  color: white;
  text-align: center;
  right: 0;
}
.userDetails {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.userDetails > span {
  font-size: 15px;
  font-family: "Poppins";
  color: rgb(175, 175, 174);
}
.profile_menu > label {
  padding: 5px 15px;
  margin: 5px 0px;
  background-color: #f5f3f3;
  border-radius: 7px;
  transition: all 0.2s ease;
}
.profile_menu > label:hover {
  background-color: #e4e2e2;
  transition: all 0.2s ease;
}
.profile_menu:hover {
  cursor: pointer;
  z-index: 1;
}
.menu:hover {
  cursor: pointer;
  z-index: 1;
  color: #0053b4;
  font-weight: 600;
}
.tours {
  z-index: 999;
}
.userProfile {
  width: 100%;
  height: auto;
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.signoutbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 3px 10px;
  margin: 10px auto;
  background-color: #5e5eec;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 150px;
}
.signoutbtn:hover {
  background-color: #2121d1;
  color: white;
}

.signoutbtn > span {
  font-size: 16px;
  padding-left: 2px;
  cursor: pointer;
}
.signoutbtn > label {
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
}
.profile1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  background-color: #fbfbfc;
  border-radius: 10px;
}
/* .userProfile > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  background-color: #fbfbfc;
  border-radius: 10px;
} */
.userProfile > main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 3px 10px;
  margin: 10px auto;
  background-color: #5e5eec;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  position: relative;
  bottom: 0px;
}
.userProfile > main:hover {
  background-color: #2121d1;
  color: white;
}
.userProfile > main > span {
  font-size: 16px;
  padding-left: 2px;
  cursor: pointer;
}
.userProfile > main > label {
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
}
.userProfile > div > img {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}
.userProfile > div > label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 21px;
  margin-left: 20px;
}
.userProfile > div > label > span {
  font-size: 14px;
  margin-left: 0px;
  color: rgb(9, 182, 182);
}
@media (max-width: 600px) {
  .signoutbtn {
    position: initial;
  }
  .mobileNav {
    width: 100%;
    height: 100px;
    margin-top: -5px;
    background-color: #ffffff;
  }
  .mynavbar {
    padding: 0px 20px 0px 0px;
    height: 70px;
  }
  .userProfile {
    margin-top: -40px;
  }
  .userProfile > div > img {
    height: 40px;
    width: 40px;
    border-radius: 5px;
  }
  .userProfile > div > label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 16px;
    margin-left: 20px;
  }
  .userProfile > div > label > span {
    font-size: 10px;
    margin-left: 0px;
    color: rgb(9, 182, 182);
  }

  .userProfile > main > span {
    font-size: 17px;
    padding-left: 2px;
    cursor: pointer;
  }
  .userProfile > main > label {
    font-size: 14px;
    cursor: pointer;
    margin-left: 5px;
  }
}
