.main {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-top: 80px;
}
.testNameWithDetails {
  width: 80%;
  /* display: flex; */
  /* justify-content: flex-start; */
}
.banner {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-around;
}
.banner > aside {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.banner > aside > h2 {
  font-size: 25px;
  font-family: "Roboto";
  font-weight: 500;
  margin: 25px auto 10px 30px;
}
.banner > aside > p {
  font-size: 17px;
  font-family: "Roboto";
  font-weight: 400;
  color: #7d7d7d;
  margin: 10px auto 10px 30px;
}
.banner > aside > ul {
  font-size: 17px;
  font-family: "Roboto";
  font-weight: 400;
  color: #7d7d7d;
  margin: 10px auto 20px 30px;
}
.banner > aside > button {
  font-size: 17px;
  padding: 8px 20px;
  border-radius: 10px;
  background-color: rgb(252, 229, 25);
  border: none;
  color: rgb(4, 1, 49);
  font-family: "Roboto";
  font-weight: 500;
  margin: 10px auto 20px 42px;
}
.banner > aside > button:hover {
  background-color: rgb(7, 2, 36);
  color: white;
}
.banner > section {
  width: 40%;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 10px;
}
.banner > section > img {
  height: auto;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}
.pricing {
  margin: 40px auto 20px auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pricing > section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing > h2 {
  font-size: 35px;
  font-family: "Roboto";
  font-weight: 500;
  margin-bottom: 15px;
}
.pricing > section > div {
  width: 23%;
  height: auto;
  max-height: 470px;
  border-radius: 10px;
  border: 2px solid rgba(230, 230, 230, 1);
  background-color: rgb(252, 255, 255);
  margin: 10px 15px;
  padding: 15px;
}
.pricing > section > div > h5 {
  font-size: 25px;
  font-family: "Roboto";
  margin: 15px auto 10px 10px;
  font-weight: 500;
}
.pricing > section > div > span {
  font-size: 20px;
  font-family: "Roboto";
  margin: auto auto auto 10px;
}
.pricing > section > div > label {
  font-size: 15px;
  font-family: "Roboto";
  margin: 5px auto auto 10px;
  display: flex;
  align-items: center;
}
.pricing > section > div > label > div {
  font-size: 25px;
  margin-right: 10px;
}
.pricing > section > div > button {
  font-size: 15px;
  margin: 15px auto;
  padding: 8px 20px;
  font-size: 18px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: none;
  font-family: "Roboto";
  background-color: rgba(71, 157, 177, 1);
}
.pricing > section > div > button:hover {
  background-color: rgb(54, 121, 136);
}
.courseDetails {
  width: 100%;
  height: auto;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.courseDetails > h2 {
  font-size: 28px;
  font-family: "Roboto";
}
.courseDetails > div {
  width: 50%;
  height: auto;
  overflow: hidden;
  border: 1px solid rgba(230, 230, 230, 1);
  margin: 10px auto;
  border-radius: 15px;
}
.pointText {
  font-size: 17px;
  font-family: "Roboto";
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: #7d7d7d;
}
.test {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  padding: 5px 15px;
  border-radius: 15px;
  overflow: hidden;
}
.test:hover {
  color: rgb(94, 94, 241);
  cursor: pointer;
  background-color: #f1f1f3;
}
.test > h5 {
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 400;
  margin: auto 0px;
  text-align: center;
}
.test > h5:hover {
  color: #6c62f5;
  cursor: pointer;
}

@media (max-width: 950px) {
  .banner > aside {
    width: 45%;
  }
  .banner > aside > h2 {
    font-size: 22px;
    margin: 25px auto 10px 20px;
  }
  .banner > aside > p {
    font-size: 15px;
    margin: 10px auto 10px 20px;
  }
  .banner > aside > ul {
    font-size: 15px;
    margin: 10px auto 20px 20px;
  }
  .banner > aside > button {
    font-size: 16px;
    padding: 6px 20px;
    margin: 10px auto 20px 30px;
  }

  .banner > section {
    width: 45%;
  }

  .pricing > h2 {
    font-size: 30px;
  }
  .pricing > section > div {
    width: 30%;
    padding: 10px;
    margin: 10px;
  }
  .pricing > section > div > h5 {
    font-size: 21px;
  }
  .pricing > section > div > span {
    font-size: 17px;
  }
  .pricing > section > div > label {
    font-size: 14px;
  }
  .pricing > section > div > label > div {
    font-size: 21px;
  }
  .pricing > section > div > button {
    font-size: 13px;
    padding: 5px 20px;
  }
}
@media (max-width: 710px) {
  .banner {
    flex-direction: column;
  }
  .banner > aside {
    width: 90%;
  }
  .courseDetails > h2 {
    font-size: 24px;
    text-align: center;
  }
  .banner > aside > h2 {
    font-size: 22px;
    margin: 5px auto 10px 20px;
  }
  .banner > aside > p {
    font-size: 15px;
    margin: 10px auto 10px 20px;
  }
  .banner > aside > ul {
    font-size: 15px;
    margin: 10px auto 20px 20px;
  }
  .banner > aside > button {
    font-size: 16px;
    padding: 6px 20px;
    margin: 10px auto 20px 30px;
  }

  .banner > section {
    width: 90%;
  }
  .banner > section > img {
    max-width: 90%;
  }
  .pricing > section {
    flex-direction: column;
    justify-content: center;
  }

  .pricing > section > div {
    width: 90%;
    padding: 10px;
    margin: 10px;
  }

  .pricing > section > div > button {
    font-size: 15px;
    padding: 8px 20px;
  }
  .courseDetails > div {
    width: 90%;
  }
  .test > h5 {
    font-size: 14px;
    margin: auto 0px;
  }
  .pointText {
    font-size: 15px;
  }
}
