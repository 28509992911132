.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 82%;
  position: fixed;
  z-index: 1;
  float: right;
  overflow: hidden;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
}
.clientname {
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-weight: 600;
  font-size: 25px;
  cursor: pointer;
  margin-left: 10px;
}
.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}

@media (max-width: 1000px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    height: 60px;
    padding: 10px 15px;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    overflow: hidden;
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
  }
}
