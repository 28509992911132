.left_curriculum {
  width: 100%;
  /* border:1px solid; */
  border-radius: 5px;
  /* margin-left: 80px; */
  margin-top: 30px;
  overflow: auto;
}
.testSelection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.testSelection > main {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
}
.testSelection > main > span {
  width: 45%;
  display: flex;
  margin: 5px 0px;
  align-items: center;
  justify-content: space-between;
}
.curriculum_heading {
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif !important;
  font-size: 15px;
  font-weight: 300;
  padding-left: 40px;
  padding-top: 15px;
}
.course_title {
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif !important;
  font-size: 20px;
  font-weight: 500;
  padding-left: 40px;
  /* padding-top: 10px; */
}

.header_main {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left_side_course {
  width: 82%;
}

.header_title {
  font-family: "Roboto", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif !important;
  font-size: 28px;
  font-weight: 600;
}

/* Form */

.section_btn {
  /* width: 150px; */
  margin: auto;
}
.section_main {
  width: 95%;
  margin: 10px auto;
  border: 1px solid lightgray;
  border-radius: 3px;
  cursor: move;
}
.mover {
  cursor: move;
  transform: rotate(90deg);
}
.section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  border-bottom: 1px solid lightgray;
  height: 50px;
}

.sectionName {
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 18px;
  padding-left: 15px;
  /* width: 150px; */
}

/* Content */

.content_add_buttons {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}

.content_heading {
  display: flex;
}

.content_main {
  width: 95%;
  margin: 20px auto;
}

.content_rows {
  display: flex;
  margin-top: 10px;
}

.first_row_content {
  display: flex;
  column-gap: 170px;
  flex-wrap: wrap;
}

.content_heading {
  width: 150px;
}
.save_btn,
.save_btn2 {
  border-style: none;
  margin-top: 20px;
  background-color: black;
  color: white;
  font-family: "Roboto", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-weight: 500;
  width: 80px;
}
.save_btn2 {
  height: 30px;
  width: 150px;
}

.content_heading_name {
  /* height: 20px; */
  width: 180px;
}

.content_Title {
  width: auto;
  max-width: 200px;
  min-width: 100px;
}
.header_btn {
  height: 17px;
}

.predefined_title {
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-weight: 500;
}
.uploadbtn {
  border: 1px solid;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
}
.content_main_heading {
  display: flex;
}

.content_edit_main {
  width: 400px;
}

.bottom_btns {
  position: fixed;
  bottom: 0;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 350px;
  background-color: white;
  box-shadow: 0 -10px 5px -10px rgba(159, 158, 158, 0.5);
}

.add_content {
  border: 1px solid;
  padding: 2px 5px;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-weight: 500;
}

.save_changes {
  cursor: pointer;
  margin-right: 20px;
}

.save_changes:hover {
  font-weight: 500;
}

.decription_input {
  width: 400px;
  height: 100px;
}

.test_content {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
}

.test_row {
  width: 500px;
}
