@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Arvo&family=Poppins&display=swap");

.main {
  background-image: radial-gradient(white, #c4f1f2, #7b94f4);
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
  min-height: 100vh;
  max-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eye {
  cursor: pointer;
}
.container {
  width: 48%;
  max-width: 95%;
  background-color: #fff;
  height: auto;
  margin: auto;
  border-radius: 15px;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
}

.navbar img {
  height: 80px;
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightSidebarContext {
  margin-left: 35px;
  margin-top: 10px;
}

.btnDiv {
  margin-top: 40px;
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.btn {
  padding: 8px 20px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #08213e;
}
.btnDiv a {
  margin-left: 40px;
  color: #000;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.moveBar {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  margin-bottom: 20px;
}
.oneHalf {
  background-color: blue;
  width: 170px;
  background: #08213e;
  border-radius: 10px;
  margin-right: 2px;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    #08213e 0%,
    #08213e 26.04%,
    #cee8ff 26.05%
  );
}
.secondHalf {
  height: 3px;
  border-radius: 20px;
}
.horn:hover {
  cursor: pointer;
  box-shadow: 0 0 2px 2px rgb(247, 234, 234);
}
.hand:hover {
  cursor: pointer;
  box-shadow: 0 0 2px 2px rgb(247, 234, 234);
}
.label1 {
  color: #000;
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: -15px;
}
.label2 {
  color: #999;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.leftSidebar {
  width: 100%;
  height: auto;
  margin: auto;
  background-color: #ffffff;
}
.leftInput input {
  border: none;
  outline: none;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  margin-left: 10px;
  background: #f9f9f9;
  color: black;
  width: 80%;
}
.leftInput select {
  border: none;
  outline: none;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  margin-left: 10px;
  background: #f9f9f9;
  color: black;
  width: 80%;
}
.leftInput {
  border: 1px solid #cee8ff;
  padding: 8px;
  border-radius: 10px;
  background: #f9f9f9;
  width: 100%;
}
.formSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-right: 50px;
  margin-top: 30px;
}

.formSection label {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 550;
}
.password {
  display: flex;
  border: 1px solid #cee8ff;
  padding: 8px;
  border-radius: 10px;
  background: #f9f9f9;
  justify-content: space-between;
  position: relative;
}
.password_validation {
  position: absolute;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  padding: 5px;
  border-radius: 10px;
}
.pswInp input {
  border: none;
  outline: none;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  margin-left: 12px;
  background: #f9f9f9;
}
.selectPhone {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #cee8ff;
  border-radius: 10px;
  background: #f9f9f9;
  padding: 8px 1px;
}
.phoneSection input {
  margin-left: 15px;
  border: 1px solid #cee8ff;
  padding: 10px;
  width: 205px;
  outline: none;
  background: #f9f9f9;
  border-radius: 10px;
  font-size: 12px;
  font-family: Poppins;
}
.formSection img {
  width: 15px;
}

.search {
  width: 98%;
}
.descriptiontext {
  align-items: center;
  width: 200px;
}

/* Form */
.Firstline,
.secondline,
.thirdline {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.childdivs {
  width: 250px;
}

.mobilenumber {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media only screen and (max-width: 850px) {
  .leftSidebar {
    width: 50%;
  }
}

@media only screen and (max-width: 750px) {
  .leftInput,
  .password {
    width: 230px;
  }
  .btnDiv a {
    margin: 0;
  }
}

@media only screen and (max-width: 550px) {
  .rightSidebar {
    display: none;
  }
  .leftSidebar {
    width: 100%;
  }
  .leftInput,
  .password {
    width: 100%;
  }
  .childdivs {
    width: 100%;
  }
  .container {
    margin: 40px auto;
    width: 90%;
  }

  .rightSidebarContext {
    width: 90%;
    margin: 0 0 0 20px;
  }
  .label1 {
    text-align: center;
  }
  .formSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Firstline,
  .secondline,
  .thirdline {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btnDiv {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.alreadyaccount {
  font-size: 12px;
  cursor: pointer;
}

.alreadyaccount:hover {
  text-decoration: underline;
}
