.container {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container > h1,
h6 {
  text-align: center;
  font-size: 28px;
}
.uploadGallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
.takePic {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
.uploadGallery > input {
  width: 100%;
  margin: 10px auto;
}
.filesSection {
  width: 50%;
  margin: auto;
}
.filesSection > ul {
  margin-top: 60px;
}
.filesSection > h4 {
  margin-top: 30px;
  text-align: center;
}
.filesSection > ul > li {
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin: 5px 0px;
}
.filesSection > button {
  margin-top: 30px;
  float: right;
}
.omrmainContainer {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
}
.uploadfiles {
  border: 1px solid;
  border-radius: 3px;
  padding: 3px 5px;
  cursor: pointer;
}
@media (max-width: 900px) {
  .omrmainContainer {
    width: 90%;
  }
}
@media (max-width: 600px) {
  .omrmainContainer {
    width: 90%;
    margin: auto;
    flex-direction: column;
    align-items: center;
  }
  .filesSection > h4 {
    margin-top: 10px;
    text-align: center;
  }
  .filesSection {
    width: 90%;
    margin: auto;
  }
  .filesSection > ul {
    margin-top: 30px;
  }
  .container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
