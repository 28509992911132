.main {
  width: 100%;
  display: flex;
  background-color: white;
  padding: 10px;
  height: auto;
  max-height: 100vh;
}
/* Upgrad modal css */
.upgrade {
  background-color: #ffffff;
  height: auto;
  width: 90%;
  border: 2px solid rgb(167, 165, 168);
  border-radius: 10px;
  margin: auto;
}
.upgrade > main {
  width: 60%;
  margin: 30px auto 10px auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upgrade > main > h4 {
  font-weight: 500;
  text-align: center;
  font-family: "Roboto";
  font-size: 24px;
}
.upgrade > main > label {
  text-align: center;
  color: #7d7d7d;
  font-weight: 500;
  margin: auto auto 10px auto;
  font-size: 14px;
}
.upgrade > main > button {
  width: 50%;
  text-align: center;
  color: #fcfcfc;
  font-weight: 500;
  background-color: rgb(106, 106, 240);
  margin: 10px auto 10px auto;
  padding: 5px;
  border-radius: 5px;
  font-size: 17px;
  font-family: "Roboto";
  border: none;
}
.mainBox {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.mainBox > div {
  width: 40%;
  height: auto;
  padding: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.mainBox > div > h5 {
  font-size: 19px;
  font-family: "Roboto";
  font-weight: 500;
}
.mainBox > div > label {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  color: #7d7d7d;
}
/* All the css of this page */
.listPoint {
  font-size: 17px;
  color: #020d2b;
  font-family: "Roboto";
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.btnNavigaton {
  border: none;
  background-color: rgb(127, 134, 240);
  font-weight: 500;
  font-family: "Roboto";
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 5px;
  color: white;
}
.btnNavigaton:hover {
  background-color: rgb(79, 79, 241);
}
.dicswithPic {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 0px auto 20px auto;
}
.dicswithPic > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.navbar {
  float: right;
  background-color: #7d7d7d;
}
.dashbord {
  width: 21%;
  overflow-y: auto;
  max-height: 100vh;
}
.main > aside {
  width: 27%;
  height: auto;
  max-height: 85vh;
  overflow-y: auto;
  font-family: "Roboto";
  font-weight: 500;
  overflow-y: auto;
  margin-top: 80px;
  /* border: 2px solid #7d7d7d; */
  border-radius: 10px;
}
.main > section {
  width: 73%;
  max-height: 85vh;
  overflow-y: auto;
  margin-top: 80px;
}
.video {
  width: 95%;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  height: 450px;
}
.para2 {
  font-size: 15px;
  font-family: "Roboto";
  background-color: rgb(255, 255, 255);
  padding: 2px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  margin: 0px auto 5px auto;
  color: rgb(118, 118, 247);
}
.para > span {
  margin-right: 10px;
  font-size: 22px;
}
.para2 > span {
  margin-right: 10px;
  font-size: 22px;
}
.para {
  font-size: 15px;
  font-family: "Roboto";
  background-color: rgb(255, 255, 255);
  padding: 2px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px auto 5px auto;
}
.para:hover {
  background-color: rgb(122, 155, 247);
  color: white;
}
.dics {
  height: auto;
  overflow-y: auto;
  width: 100%;
  padding: 10px 30px;
  margin: 20px auto auto auto;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.dics > label {
  width: 100%;
  margin: auto;
  font-size: 15px;
  color: #7d7d7d;
}
.dics > label > span {
  font-size: 17px;
  color: #000000;
}
.dics > h3 {
  font-family: "Roboto";
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dics > h3 > span {
  font-family: "Roboto";
  display: flex;
  cursor: pointer;
  font-size: 17px;
  justify-content: space-between;
  align-items: center;
}
.dics > h3 > span > input {
  margin-right: 10px;
  height: 15px;
  width: 15px;
}
.dics > span {
  width: 20%;
  font-size: 15px;
  display: flex;
  font-family: "Roboto";
  align-items: center;
  justify-content: center;
}
.errorMessage {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.details {
  height: auto;
  padding: 10px;
  width: 85%;
  margin: auto;
  background-color: #ffffff;
}
.details > label {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7d7d7d;
}
.details > main {
  height: 100px;
  width: 95%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

@media (max-width: 985px) {
  .upgrade > main {
    width: 100%;
  }
  .dashbord {
    width: 0%;
  }
}
@media (max-width: 750px) {
  .dashbord {
    display: none;
  }
  .listPoint {
    font-size: 15px;
  }
  .main {
    flex-direction: column;
    height: auto;
    padding: 0;
    max-height: max-content;
  }
  .dicswithPic {
    height: auto;
    width: 95%;
    margin: 70px auto 20px auto;
  }
  .dicswithPic > img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 170px;
    object-fit: contain;
  }
  .main > aside {
    width: 90%;
    height: auto;
    border: none;
    max-height: max-content;
    overflow-y: hidden;
    margin: auto;
  }
  .main > section {
    width: 90%;
    height: auto;
    max-height: none;
    margin: 75px auto 10px auto;
  }
  .para {
    font-size: 14px;
  }
  .details {
    width: 100%;
    padding: 0px;
  }
  .details > label {
    font-size: 15px;
  }
  .video {
    width: 100%;
    margin: auto;
    height: auto;
  }
  .dics {
    height: auto;
    overflow-y: auto;
    width: 100%;
    padding: 10px 10px;
    margin: 10px auto 10px auto;
    background-color: rgb(255, 255, 255);
  }
  .dics > h3 {
    font-size: 17px;
    font-family: "Roboto";
    font-weight: 500;
  }
  .dics > h3 > span {
    font-size: 13px;
    font-weight: 400;
  }
  .dics > h3 > span > input {
    margin-right: 8px;
    height: 13px;
    width: 13px;
  }
  /* Upgrad modal css */
  .upgrade {
    width: 90%;
    border: none;
    margin: auto auto 10px auto;
  }
  .upgrade > main {
    width: 90%;
    margin: 20px auto 10px auto;
  }
  .upgrade > main > h4 {
    font-weight: 500;
    font-size: 21px;
  }
  .upgrade > main > button {
    width: 60%;
    font-size: 15px;
  }
  .mainBox > div {
    width: 100%;
  }
  .mainBox > div > h5 {
    font-size: 17px;
  }
  .mainBox > div > label {
    font-size: 14px;
  }
}
