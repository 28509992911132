@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.mainContainer {
  height: 100vh;
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  display: flex;
}
.navBar {
  height: 75px;
  width: 80%;
  padding: 0px 43px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
}
.navBar > span {
  display: flex;
  font-size: 32px;
  font-family: arvo, sans-serif;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.navBar > span > label {
  display: flex;
  font-size: 32px;
  font-family: arvo, sans-serif;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navBar > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;

  cursor: pointer;
  border-radius: 50%;
}
.slider {
  float: left;
  overflow-y: auto;
  height: 100%;
  scroll-behavior: none;
  background-color: #001529;
  transition: all 0.2s ease-in-out;
}

.contentSideSmall {
  width: 100%;
  float: right;
  height: auto;
  overflow-y: auto;
  background-color: #f2f5f9;
  transition: all 0.2s ease-in-out;
  /* padding: 0 10px; */
}
.contentSideSmall > h4 {
  font-family: "Poppins";
  margin-left: 40px;
  margin-top: 15px;
}
.contentSide {
  transition: all 0.2s ease-in-out;
  width: 100%;
  overflow-y: auto;
  float: right;
  height: 100%;
  background-color: #f2f5f9;
}

.stdDetails {
  width: 90%;
  margin: 15px auto 10px auto;
  height: auto;
}
.stdDetails > label {
  font-size: 33px;
}
.userProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 20px 0px;
  margin: 20px auto;
  width: 100%;

  /* border-top: 1px solid wheat; */
  border-bottom: 2px solid rgb(156, 154, 154);
}

.userProfile > img {
  height: 75px;
  width: 70px;
  border-radius: 50%;
}
.userProfile > label {
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
  /* color: rgb(255, 255, 255); */
}
.userProfile > span {
  font-size: 14px;
}
.cards {
  width: 100%;
  margin: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 25px;
}
.card {
  width: 45%;
  min-width: 200px;
  background-color: white;
  border-radius: 12px;
  height: auto;
  min-height: 200px;
  transition: transform 0.4s ease;
  padding-bottom: 10px;
  box-shadow: 1px 1px 10px 1px rgb(150, 148, 148);
  cursor: pointer;
  padding-bottom: 14px;
}
.card > label {
  margin: 18px auto auto 25px;
  font-size: 17px;
  font-family: Poppins;
}
.card > span {
  margin: 28px auto auto 25px;
  font-size: 14px;
  color: rgb(171, 102, 235);
  font-weight: 400;
  cursor: pointer;
  font-family: "Poppins";
}
.card > span:hover {
  color: rgb(103, 1, 199);
}
.card > div {
  height: 80px;
  margin: 5px auto 10px auto;
  width: 84%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}
.card > div > span {
  font-size: 28px;
  color: #f9fdf7;
  padding: 4px 11px;
  background-color: #57ca22;
  border-radius: 13px;
  box-shadow: 1px 1px 4px 1px rgb(97, 96, 96);
}
.carousel-item.active {
  display: none !important;
}

.commonText {
  font-family: "Poppins";
}

.recordingBtn {
  color: blueviolet;
}
.recordingBtn:hover {
  color: rgb(55, 2, 105);
}
/* .card:hover {
  transform: scale(1.06); 
  transition: transform 0.4s ease; 
} */
.card > div > h5 {
  margin-left: 35px;
  margin-top: 10px;
  font-size: 42px;
  font-family: "Poppins";
}
#center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 95%;
  margin: auto;
  column-gap: 20px;
}
.dashboardlanding {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}
.allCard {
  width: 65%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
.practice {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 34.5%;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.practice > label {
  width: 100%;
  text-align: center;
  font-size: 23px;
  margin: 15px auto 10px auto;
}
.practice > span {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  margin: auto auto auto auto;
}
.practice > div {
  width: 90%;
  height: 25px;
  border-radius: 50px;
  margin: 10px auto;
}
.practice > div > label {
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  width: 100%;
  font-family: "Poppins";
}
.practice > button {
  border: none;
  background-color: white;
  color: blueviolet;
  font-family: "Poppins";
  margin: auto auto 15px auto;
}
.practice > button:hover {
  color: rgb(55, 2, 105);
}
.mockCard {
  width: 250px;
  background-color: #ffffff;
  height: auto;
  padding: 20px;
  margin: 15px;
  /* box-shadow: 1px 1px 15px 1px rgb(150, 148, 148); */
  border-radius: 13px;
}

.skill_label {
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  width: 100px;
}

.mockheading {
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  /* font-weight: 500; */
  font-size: 18px;
}

.description {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.description_title {
  width: 80px;
}
.description_icon {
  width: 28px;
  font-size: 17px;
  color: #1e88e5;
}

.action_btn {
  color: #1e88e5;
  font-weight: 500;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 15px;
  cursor: pointer;
}

.action_btn:hover {
  font-weight: 600;
}

.labels {
  font-family: "Poppins";
  font-weight: bold;
}
.validation {
  display: flex;
  font-size: 14px;
  height: 70px;
  margin: 20px auto;
  /* justify-content: space-between; */
  column-gap: 40px;
  align-items: center;
  flex-wrap: wrap;
}
.mockCard > label {
  font-size: 22px;
}
.startbutton {
  padding: 2px 7px;
  border: none;
  background-color: blueviolet;
  color: white;
  border-radius: 5px;
}
.startbutton:hover {
  padding: 2px 7px;
  border: none;
  border-radius: 5px;
  background-color: rgb(20, 8, 126);
  color: rgb(255, 255, 255);
}
.testSeries{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card_footer {
  display: flex;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
  align-items: flex-start;
}

.menulabel {
  font-size: 14px;
  font-family: Inter, "Segoe UI", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji";
  font-weight: 600;
}

.breadcrumb {
  margin-left: 50px;
  margin-top: 90px;
  font-size: 18px;
  font-family: "Poppins";
}

.signout {
  position: fixed;
  bottom: 100px;
  background-color: #57ca22;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
  padding-inline: 13px;
}

.profile {
  display: flex;
}
.practice_submain {
  width: 95%;
  margin: 60px auto auto auto;
  background-color: #f2f5f9;
}

.each_main {
  width: 150px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
.difficulties_main {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 992px) {
  .navBar {
    width: 100%;
  }
}
@media (max-width: 1363px) {
  .dashboardlanding {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .allCard {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
  }
  .practice {
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 98%;
    margin: 10px auto 20px auto;
  }
}



.attendanceLabels {
  margin-bottom: 0.3rem;
  font-size: 16px;
  font-family: "Poppins";
}

.attendance_details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}

/* Sessions CSS */

.Join_btn {
  border: 1px solid initial;
  border-radius: 5px;
  padding: 3px 15px;
  font-size: 15px;
  text-decoration: none;
  color: white;
  font-weight: 600;
  background-color: rgb(69, 39, 160);
  float: right;
}
.Join_btn:hover {
  color: white;
}

.upcoming_thirdrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 15px; */
}

.upcoming_maindiv {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* height: 100%; */
  position: relative;
  margin-top: 25px;
}

.upcoming_session_title {
  font-size: 18px;
  font-weight: 600;
}

.previous_heading {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-top: 7px;
}

.label_container {
  flex-grow: 1;
  text-align: center;
}

.card_heading {
  font-size: 17px;
  font-family: "Poppins";
}

.nextprev_icon1,
.nextprev_icon {
  position: absolute;
  top: 45%;

  font-size: 16px;
  text-align: right;
  width: 30px;
  height: 30px;
  padding-left: 7px;
  border-radius: 50%;
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  text-align: center;
}
.nextprev_icon1:hover,
.nextprev_icon:hover {
  background-color: rgb(224, 221, 221);
}

.nextprev_icon1 {
  left: -10px;
}

.nextprev_icon {
  right: -10px;
}

/* New Dashboard */

.dashboard_new {
  border-radius: 5px;
  width: 95%;
  margin: auto;
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.left_side {
  width: 65%;
}

.right_side {
  width: 33%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.first_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* First Card Left Side */
.first_row_first_card,
.first_row_first_card1 {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  border-radius: 12px;
  border: none rgba(144, 202, 249, 0.145);
  color: rgb(255, 255, 255);
  overflow: hidden;
  position: relative;
  width: 48%;
  height: auto;
  min-height: 200px;
}

.first_row_first_card {
  background-color: rgb(94, 53, 177);
}
.first_row_first_card1 {
  background-color: rgb(30, 136, 229);
}

.first_row_first_card::before,
.first_row_first_card1::before {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  top: -125px;
  right: -15px;
  opacity: 0.5;
}

.first_row_first_card::before {
  background: rgb(69, 39, 160);
}
.first_row_first_card1::before {
  background: rgb(21, 101, 192);
}

.first_row_first_card::after,
.first_row_first_card1::after {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;

  border-radius: 50%;
  top: -85px;
  right: -95px;
}

.first_row_first_card::after {
  background: rgb(69, 39, 160);
}

.first_row_first_card1::after {
  background: rgb(21, 101, 192);
}

/* Title and Icons */

.first_card_title {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  width: 80%;
  column-gap: 20px;
  margin: 15px auto;
}

/* Card Title */

.card_title {
  margin: 0px;
  font-size: 1rem;
  color: white;
  font-weight: 600;
  font-family: "Poppins" !important;
  line-height: 1.235;
  z-index: 999;
}

/* First card icon */
.card_icon,
.card_icon2 {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  overflow: hidden;
  user-select: none;
  color: white;

  cursor: pointer;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  font-size: 1.5rem;
  margin-top: 8px;
}

.card_icon {
  background: rgb(69, 39, 160);
}

.card_icon2 {
  background: rgb(21, 101, 192);
}

.card_details {
  width: 80%;
  margin: auto;
}

.details_label {
  width: 60%;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.details_label2 {
  width: 60%;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.session_title,
.session_title1 {
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 16px;

  width: 140px;
  cursor: pointer;
}

.session_title {
  color: white;
}

.session_title1 {
  color: black;
}

.right_upcomingSessions,
.right_expiredSessions {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  border-radius: 12px;
  border: none rgba(144, 202, 249, 0.145);
  color: rgb(238, 242, 246);
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0 0.5rem 0 0rem;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.right_upcomingSessions {
  background-color: rgb(30, 136, 229);
}

.right_expiredSessions {
  background-color: rgb(255, 255, 255);
  padding: 0px 6.5px;
}

.detail_view {
  margin: 8px 0 0 35px;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.detail_view:hover {
  text-decoration: underline;
}

.right_upcomingSessions::before,
.right_expiredSessions::before {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;

  border-radius: 50%;
  top: -160px;
  right: -130px;
}

.right_upcomingSessions::before {
  background: linear-gradient(
    140.9deg,
    rgb(144, 202, 249) -14.02%,
    rgba(144, 202, 249, 0) 77.58%
  );
}

.right_expiredSessions::before {
  background: linear-gradient(
    140.9deg,
    rgb(255, 193, 7) -14.02%,
    rgba(144, 202, 249, 0) 70.5%
  );
}

.right_upcomingSessions::after,
.right_expiredSessions::after {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;

  border-radius: 50%;
  top: -30px;
  right: -180px;
}

.right_upcomingSessions::after {
  background: linear-gradient(
    210.04deg,
    rgb(144, 202, 249) -50.94%,
    rgba(144, 202, 249, 0) 83.49%
  );
}
.right_expiredSessions::after {
  background: linear-gradient(
    210.04deg,
    rgb(255, 193, 7) -50.94%,
    rgba(144, 202, 249, 0) 83.49%
  );
}

.right_upcomingSessions_dates,
.right_upcomingSessions_dates2 {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: Roboto, sans-serif;
  line-height: 1;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  border-radius: 8px;
  width: 70px;
  height: 70px;
  font-size: 1rem;
  color: rgb(255, 255, 255);
}
#month {
  margin: 3px auto;
  font-size: 18px;
}

.right_upcomingSessions_dates {
  background: rgb(21, 101, 192);
}
.right_upcomingSessions_dates2 {
  color: black;
  background-color: rgb(255, 248, 225);
}

.skeleton {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.practice_chart {
  margin-top: 20px;
}
.practice_chart2 {
  display: none;
}

@media (max-width: 1200px) {
  .left_side {
    width: 100%;
  }
  .right_side {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .practice_chart {
    display: none;
  }
  .practice_chart2 {
    display: block;
    width: 100%;
  }
  .right_upcomingSessions,
  .right_expiredSessions {
    width: 48%;
  }
}

@media (max-width: 688px) {
  .contentSide {
    transition: all 0.2s ease-in-out;
    width: 100%;
    overflow-y: auto;
    float: right;
    height: auto;
    background-color: #f2f5f9;
  }

  .first_row_first_card,
  .first_row_first_card1,
  .right_upcomingSessions,
  .right_expiredSessions {
    width: 100%;
  }

  .first_row {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .validation {
    margin-bottom: 65px;
  }
  .dashboardlanding {
    width: 95%;
    margin: 10px auto;
  }
  .navBar {
    height: 55px;
    width: 100%;
    padding: 0px 20px;
    top: 0;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
  }
  .card {
    width: 100%;
    background-color: white;
    border-radius: 12px;
    height: auto;
    /* margin: 15px; */
    box-shadow: 1px 1px 15px 1px rgb(150, 148, 148);
  }
  .card > label {
    margin: 18px auto auto 25px;
    font-size: 18px;
    text-align: center;
  }
  .card > div {
    height: 80px;
    margin: 10px auto 10px auto;
    width: 84%;
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }
  .card > div > span {
    font-size: 38px;
    color: #f9fdf7;
    padding: 0px 9px;
    background-color: #57ca22;
    border-radius: 13px;
    box-shadow: 1px 1px 9px 1px rgb(97, 96, 96);
  }
  .card > div > h5 {
    margin-left: 35px;
    margin-top: 10px;
    font-size: 35px;
    font-family: "Poppins";
  }
  .contentSide > h2 {
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
    font-family: "Poppins";
  }
  .stdDetails {
    text-align: center;
  }
  .stdDetails > label {
    font-size: 23px;
  }
}

.sessions_title {
  display: flex;
  flex-direction: column;
}
@media (max-width: 300px) {
  .right_upcomingSessions {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px 0;
  }
  .sessions_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }

  .session_title {
    width: 50%;
  }
}
