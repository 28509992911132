.manage_main {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 90%;
}
.subjectlist {
  margin: auto auto 30px 20px;
}
.manage_sub {
  width: 500px;
  border: 1px solid;
  height: auto;
  min-height: 200px;
  max-height: 400px;
  border-radius: 5px;
  margin: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.accounttype {
  width: 50px;
  color: white;
  height: 22px;
}

.freeaccount {
  border-radius: 5px;
  text-align: center;
  width: 50px;
  background-color: green;
}
.manage_accounts {
  display: flex;
  justify-content: space-between;
}
.paidaccount {
  border-radius: 5px;
  text-align: center;
  width: 50px;
  background-color: blue;
}
