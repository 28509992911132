@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

.adminMock {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.errorPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
}
.uploadfiles {
  border: 1px solid;
  border-radius: 3px;
  padding: 3px 5px;
  cursor: pointer;
}
.userProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  padding: 20px 0px;
  margin: 20px auto;
  width: 100%;
  /* border-top: 1px solid wheat; */
  /* border-bottom: 1px solid wheat; */
}
.userProfile > img {
  height: 75px;
  width: 70px;
  border-radius: 50%;
}
.userProfile > label {
  margin-top: 10px;
  font-size: 20px;

  color: rgb(255, 255, 255);
}
.userProfile > span {
  font-size: 14px;
  color: rgb(255, 255, 255);
}
.rightMockTest {
  width: 85%;
  overflow: hidden;
  height: 100vh;
  background-color: #f2f5f9;
  border-radius: 10px;
  overflow-y: auto;
}
.leftMockTest {
  display: flex;
  height: 100vh;
  width: 18%;
  overflow-y: auto;
  box-shadow: 0px 0px 4px 4px rgba(206, 232, 255, 0.25);
}
.leftBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 390px;
  overflow-y: auto;
}
.leftMockTest h5 {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 82%;
  position: fixed;
  z-index: 1;
  float: right;
  overflow: hidden;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
}

.searchinput {
  width: 300px;
  border-style: none;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
}
.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}
.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.nav img {
  margin-left: 10px;
}
.create hr {
  width: 500px;
  margin: auto auto auto 20px;
}
.create {
  display: flex;
  align-items: center;
  width: 95%;

  height: 60px;
  font-size: 12px;
  padding: 20px 0px 10px 20px;
  position: fixed;
  justify-content: space-between;
  margin: 60px auto auto auto;
  z-index: 2;
  background-color: white;
}
.mockItem {
  margin-top: 120px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  border-radius: 9px;
  margin-left: 20px;
}
.rightNavbar {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.rightNavbar > div {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}
.rightNavbar button {
  border-radius: 0px 5px 5px 0px;
  background: #77bfff;
  border: none;
  padding: 3.3px 8px;
}
.nav {
  color: #8e8f8e;
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.leftMockTest a {
  margin-left: 15px;
  color: #004ca5;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  text-decoration-line: underline;
}
.testCard {
  width: 22.5%;
  min-width: 220px;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
}

.Cardimg {
  /* background-image: url("../../icons/cardImage.svg"); */
  height: 110px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-bottom: 1px solid;
}
.Cardimg > label {
  width: 90%;
  margin: auto;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Cardimg > label > p {
  display: flex;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  font-weight: 500;
  margin: auto 0px;
  line-height: normal;
}
.Cardimg > label > p > img {
  padding-right: 5px;
}
.Cardimg > label span {
  color: white;
  font-size: 27px;
  display: flex;

  align-items: center;
  justify-content: left;
}
.mockTestname {
  width: 100%;
  height: 100px;

  mix-blend-mode: luminosity;
}
.testname {
  width: 90%;
  margin: 0 10px;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.cardDetails {
  width: 100%;
  margin: 10px auto auto auto;
}
.cardDetails > label {
  width: 90%;
  margin: auto 12px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.cardDetails > label > p > span {
  font-size: 11px;
  font-weight: 500;
}
.cardDetails > label > p {
  font-size: 12px;
  font-weight: 600;
}
.details {
  width: 90%;
  margin: 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.details > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inviteBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 10px auto auto auto;
}
.inviteBtn > button {
  padding: 1px 12px;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
}
.assignBtn {
  padding: 0px 12px;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
}
.inviteBtn > label {
  font-size: 13px;
}
.ImgOpt {
  width: 45%;
  height: auto;
  position: absolute;
  left: 130px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 5px;
  background-color: whitesmoke;
}
.EditOpt {
  width: 60%;
  height: auto;
  position: absolute;
  left: 70px;
  bottom: 35px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 5px;
  background-color: whitesmoke;
}
.addtosection {
  border: 1px solid;
  margin-right: 20px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.lefttogglesetting {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 45%;
}
.menuwraper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.menuwraper:hover {
  font-weight: 600;
}
.segment {
  width: 50%;
  height: auto;
  position: relative;
  left: 140px;
  top: -145px;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  border-radius: 10px;
  padding: 10px;
}

.sharelink {
  color: blue;
  cursor: pointer;
  border: 1px solid #8e8f91;
  border-radius: 10px;
  padding: 5px;
}

#cardOpt {
  width: 100%;
  display: flex;
  padding: 5px;
  border-radius: 10px;
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  justify-content: space-between;
}
#cardOpt > label {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0px 5px;
  font-size: 14px;
  cursor: pointer;
  margin: 1px 0px;
}
#cardOpt > label:hover {
  color: white;
  background-color: #000;
}
#cardOpt > label > span {
  padding: 0px 5px 0px 0px;
}
.cardOpt div:hover {
  box-shadow: 0px 0px 4px 1px rgba(119, 191, 255, 0.6);
}
/* Pagination  */
.pagination {
  display: flex;
  justify-content: space-around;
  width: 130px;
  flex-wrap: wrap;
  padding: 5px 0px;
  border-radius: 5px;
}
.activePage {
  height: 35px;
  width: 70px;
  min-width: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  border-radius: 5px;
}
.active {
  color: black;
  font-size: 20px;
  margin: auto 2px;
}
.total {
  color: rgb(82, 81, 81);
  font-size: 20px;
  margin: auto 2px;
}
.nextprevbtn {
  font-size: 23px;
  margin: auto 0px;
  color: #08213e;
  cursor: pointer;
}
.disableArrow {
  color: rgb(153, 153, 153);
  font-size: 23px;
  margin: auto 0px;
}
#clicked {
  border-radius: 4px;
  border: 1px solid #77bfff;
  background: #fff;
  box-shadow: 0px 0px 4px 1px rgba(119, 191, 255, 0.6);
  display: flex;
  padding: 6px 10px;
  align-items: center;
}
#clicked:hover {
  border: 1px solid #a19ea8;
  cursor: pointer;
}
.hover {
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 0px 4px 1px rgba(119, 191, 255, 0.6);
  display: flex;
  padding: 6px 10px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  width: auto;
  margin: 10px 11px;
}
.hover:hover {
  border: 1px solid #a6a3ad;
  cursor: pointer;
}
/*Details Modal Style */
/* Modal Css */

#mco_table > div:nth-child(even) {
  border-radius: 10px;
  border-top: 1px solid #c6c5c5;
  border-bottom: 1px solid #c6c5c5;
  background: #f0f0f0;
}

.mock_table_data1 {
  width: 740px;
  display: flex;
  position: relative;
  border-radius: 10px;
  border-top: 1px solid #c6c5c5;
  border-bottom: 1px solid #c6c5c5;
  background: #fff;
  margin-top: 5px;
}
.mock_table_data1 > span {
  width: 20%;
  padding: 10px 0 10px 0;
}

#mock_table_types {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #08213e;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
}

#mock_table_tag {
  color: #08213e;
  text-align: center;
  padding-top: 10px;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
}

.mock_table_diff {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.mock_table_diff > span {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.addSegment {
  position: absolute;
  cursor: pointer;
  color: #004ca5;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-style: normal;
  border: 1px solid #f1f1f1;
  line-height: normal;
  text-decoration-line: underline;
  bottom: 0;
  margin: 10px 5%;
  width: 90%;
  box-shadow: 0px 0px 4px 1px rgba(119, 191, 255, 0.6);
  padding: 6px 10px;
}

.openmenu {
  box-shadow: 0px 0px 4px 1px rgba(119, 191, 255, 0.6);
}

/* Assign Test */

.headings {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.titlechangeinput {
  width: 100%;
  display: flex;
  border-radius: 10px;
  border: 1px solid #b9b9b9;
  background: #fff;
  padding: 10px 336px 10px 30px;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.datechangeinput {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #b9b9b9;
}
@media (max-width: 1200px) {
  .rightMockTest {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background-color: #f2f5f9;
    border-radius: 10px;
    overflow-y: auto;
  }
  .rightNavbar {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    height: 60px;
    padding: 10px 15px;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .mockItem {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-radius: 9px;
    margin-left: 20px;
  }
}
@media (max-width: 500px) {
  .mockItem {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    margin-left: 0px;
  }
  .searchinput {
    border-style: none;
    border: 1px solid;
    border-radius: 5px;
    height: 30px;
    width: auto;
  }
  .testCard {
    width: 90%;
    min-width: 0;
    overflow: hidden;
    border-radius: 10px;
    margin: 10px;
    border: 1px solid #eaeaea;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    position: relative;
  }
}

.filter_label {
  font-size: 16px;
  font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  margin-right: 10px;
}

.dropdown {
  background-color: #08213e;
}
