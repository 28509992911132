.header_main {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_title {
  font-family: "Roboto", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif !important;
  font-size: 28px;
  font-weight: 600;
  cursor: pointer;
}
.innerContact > div {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.innerContact > div > label {
  font-size: 17px;
}
.clientdetails {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
}

.social {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.contactsDiv {
  margin-top: 16px;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* background-color: red; */
  gap: 12px;
}

.AddEle {
  display: flex;
  /* justify-content: space-between; */

  /* align-items: center; */
  gap: 18px;
  min-width: 48%;
  /* background-color: green; */
}

.icon {
  margin-top: 5px;
  padding-top: 3px;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: lightgrey;
}

.innerContact {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  top: 8px;
}

.label:hover {
  cursor: pointer;
}
.label {
  text-decoration: underline;
}
.titles {
  font-weight: 500;
}

.contact_info {
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.clientdetailssub {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.labels {
  font-family: "Poppins";
  font-weight: 500;
  width: 150px;
}
