.edit_select {
  width: 82.5%;
}

.titles {
  width: 100px;
}

.buttongrad {
  margin-right: 5px;
  background: linear-gradient(135deg, #6253e1, #04befe);
  opacity: 1;
  transition: all 0.3s;
  position: relative;
}

.buttongrad::before {
  content: '';
  background: linear-gradient(135deg, #6253e1, #04befe);
  position: absolute;
  inset: 0;
  opacity: 1;
  transition: all 0.3s;
  border-radius: inherit;
}

.buttongrad:hover::before {
  opacity: 0;
}

.questionheading {
  width: 100%;
  height: 35px;
  border-style: none;
  border: 1px solid;
  border-radius: 5px;
}

.questionheading_main {
  width: 100%;
}

.difficultyoptions {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.radiobutton {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.mcqoptions {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  width: 100%;
}

.example {
  border-radius: 10px;
  height: 20vh;
}


.editor {
  width: 100%;
  height: 40vh;
}


.addmore {
  font-size: 15px;
  margin-left: 15px;
  cursor: pointer;
}
