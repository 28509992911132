.main {
  width: 85%;
  margin: 0 auto;
  background-color: #f8f8f8;
}
.uploadFiles {
  display: flex;
  width: 100%;
  align-items: center;
}
.uploadFiles > main {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 55px;
}
.settingBox {
  height: 40px;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.HeaderTopics {
  width: 100%;
  display: flex;
  position: sticky;
  top: 0px;
  background-color: #f8f4f4;
  align-items: center;
  z-index: 1;
  justify-content: space-between;
  font-weight: 600;
}
.HeaderTopics > ul {
  width: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#tableTopic {
  position: sticky;
  top: 10px;
  height: 30px;
  margin-left: -15px;
  padding-right: 45px;
}
.slider {
  display: flex;
  overflow-y: auto;
  height: auto;
  width: 18%;
}
.mainBox {
  display: flex;
  justify-content: space-between;
}
.rightNavbar {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 82%;
  top: 0;
  position: fixed;
  z-index: 1;
  float: right;
  overflow: hidden;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
}
.rightNavbar > div {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}

.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}
.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.heading {
  display: flex;
  justify-content: center;
  color: #e3cd07;
  margin-top: 60px;
  text-transform: capitalize;
}
.first_row {
  display: flex;
  justify-content: space-between;
  margin: 0 40px;
  flex-wrap: wrap;
  gap: 5px;
}
.second_row {
  display: flex;
  justify-content: space-between;
  margin: 25px 40px;
  flex-wrap: wrap;
  gap: 5px;
}

.first_row_input {
  width: 250px;
  height: 35px;
  border-radius: 5px;
  border: 1.2px solid #6e6e6f;
  background: #fff;
  box-shadow: 4px 4px 4px -1px rgba(95, 95, 99, 0.5);
  cursor: pointer;
  text-align: center;
  cursor: pointer;
}
.first_row_Select {
  width: 250px;
  height: 35px;
  border-radius: 5px;
  /* border: 1.2px solid #6e6e6f; */
  background: #fff;
  box-shadow: 4px 4px 4px -1px rgba(95, 95, 99, 0.5);
  cursor: pointer;
  text-align: center;
  cursor: pointer;
}
.first_row_duration {
  width: 250px;
  height: 35px;
  border-radius: 5px;
  /* border: 1.2px solid #6e6e6f; */
  /* background: #fff; */
  /* box-shadow: 4px 4px 4px -1px rgba(95, 95, 99, 0.5); */
  cursor: pointer;
  text-align: left;
  cursor: pointer;
  margin-left: 10px;
}

.first_row_item {
  display: flex;
  flex-direction: column;
  width: 250px;
  /* flex-grow: 1;  */
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin-right: 10px;
}

/* Second Row */
.second_row_questionbank {
  width: 250px;
  height: 35px;
  border-radius: 5px;

  cursor: pointer;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkboxes {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 5px;
}

.questionbankcheckbox {
  background: #fff;
  border: 1px solid #b7b3b3;
  border-radius: 5px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.questionbanklabel {
  color: #8b8c8c;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
}
.typeslabel {
  color: #8b8c8c;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
  margin-left: 5px;
}

.myspinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  animation: morph 2s infinite;
}

@keyframes morph {
  0%,
  100% {
    border-radius: 50%;
    transform: rotate(0deg);
    background-color: #1b1642;
  }
  50% {
    border-radius: 0%;
    transform: rotate(180deg);
    background-color: #ffde59;
  }
}

.settingandselection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 40px;
}

.settings {
  width: 40vw;
  border-right: 1px solid;
  padding-right: 50px;
}
.selection {
  width: 46vw;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.practicemode {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .selection {
    width: 90vw;
    order: 1;
    height: 300px;
    overflow: auto;
  }

  .settings {
    width: 90vw;
    order: 2;
    padding-right: 5px;
    margin-top: 20px;
    border-style: none;
  }
}

@media only screen and (max-width: 700px) {
  .first_row,
  .second_row {
    display: flex;
    justify-content: center;
  }
  .settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lefttogglesetting {
    width: 200px !important;
    margin-top: 5px;
  }
  .practicemode {
    width: 200px;
  }
}

.selectionmain {
  margin-top: 10px;
}
/* Table CSS */
.selectionmain > table {
  width: 90%;
  margin-left: 20px;
}
.selectionmain > table > tbody > tr,
.selectionmain > table > thead > tr {
  height: 30px;
  background: none;
}
.selectionmain > table > tbody > tr > td:nth-child(1),
.selectionmain > table > thead > tr > th:nth-child(1) {
  padding: 0;
  text-align: left;
  width: 25%;
}
.selectionmain > table > thead > tr > th:not(:nth-child(1)),
.selectionmain > table > tbody > tr > td:not(:nth-child(1)) {
  font-weight: 500;
  text-align: center;
  width: 200px;
}

.numberinput {
  width: 100px;
  margin: 2px 2px;
}

/* Dates */
.dates {
  border-style: none;
  border-bottom: 1px solid;
  background: none;
}

.leftsettings {
  display: flex;
  line-height: 30px;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
}
.pdfSec {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.preview {
  height: 300px;
  width: 36%;
  margin: auto;
}
.preview > div {
  margin: 0px 0px 0px 60px;
  width: 60%;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.passage {
  width: 97%;
  margin: auto;
  padding: 10px;
  max-height: 350px;
  overflow-y: auto;
}
.detailsPassage {
  display: flex;
  flex-direction: column;
}
.detailsPassage > label {
  font-size: 16px;
  font-family: "Roboto";
  margin-bottom: 5px;
}
.detailsPassage > div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detailsPassage > div > h5 {
  min-width: 19%;
  max-width: 23%;
  padding: 6px;
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
}
.pdfSec > div {
  width: 40%;
  height: 40px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdfSec > div > button {
  margin: 10px;
}
.leftsettings1 {
  /* height: 80px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 20px 0px; */
}
.lefttogglesetting {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  line-height: 30px;
}

.submitbtn {
  /* width: 150px; */
  font-weight: 600;
}
@media (max-width: 1000px) {
  .slider {
    display: none;
  }
  .main {
    width: 100%;
    margin: 0 auto;
    /* padding-top: 10px; */
    /* margin-top: 60px; */
    background-color: #f8f8f8;
  }
}
